import * as Yup from "yup";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


export const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First Name is required!"),
  lastName: Yup.string().trim().required("Last Name is required!"),
  email: Yup.string().trim().email("Invalid email format").required("Email is required!"),
  password: Yup.string().trim().required("Password is required!"),
  confirmPassword: Yup.string()
    .trim().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  phone: Yup.string().trim().required("Phone Number is required!").matches(phoneRegExp, 'Phone number is not valid'),
});
