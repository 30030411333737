import { Connection } from "../services/http/request";

let baseUrl: string | undefined;

if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
  baseUrl = process.env.REACT_APP_LIVE_BASEURL;
} else {
  baseUrl = process.env.REACT_APP_STAGING_BASEURL;
}

class AppSingleton {
  connection: Connection;

  constructor() {
    this.connection = new Connection(baseUrl || "http://localhost:3000", {});
  }
}
export const appSingleton = new AppSingleton();
