import React from "react";
import { GiFlowerEmblem } from "react-icons/gi";
import Footer from "../../components/Footer/Footer";

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto py-24">
        <article>
          <h1 className="text-5xl text-center font-bold tracking-tight text-shadow-sm">
            Car Rental Terms and Conditions
          </h1>
          <div className="flex items-center justify-center gap-1 text-sunshine my-4">
            <div className="justify-end">
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-10 flex justify-end">
                <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
              </div>
            </div>
            <GiFlowerEmblem className="text-2xl" />
            <div>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
          </div>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">1. Rental Duration</h2>
            <p>
              The rental period begins at the scheduled pickup time and ends at
              the agreed-upon drop-off time. Extension requests are subject to
              availability and additional charges.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">2. Designated Driver Services</h2>
            <p>
              When booking designated driver services, the driver will be provided
              to operate the rented vehicle. The designated driver is bound by the
              same terms and conditions as the renter.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">3. Driver Qualifications</h2>
            <p>
              All designated drivers provided by our service are licensed,
              experienced, and have undergone background checks. The renter may
              not replace the designated driver with someone else.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">4. Pickups and Drop-off Locations</h2>
            <p>
              The assigned driver will pick up and drop off renter and their
              guests at the locations specified during the reservation process.
              Changes to these locations must be communicated in advance.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">5. Fees and Payments</h2>
            <ul>
              <li>
                Rental fees are based on the agreed-upon rate and may include
                additional charges for late returns or exceeding mileage limits.
              </li>
              <li>
                A security deposit is required at the start of the rental and will
                be refunded upon the vehicle's return in the specified condition.
              </li>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">6. Responsibilities</h2>
            <ul>
              <li>
                The rental is responsible for any damage to the vehicle during the
                rental period.
              </li>
              <li>
                Any accidents or damage must be reported to the rental company
                immediately.
              </li>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">7. Accidents and Emergencies</h2>
            <ul>
              <li>
                In the event of an accident, the renter and designated driver must
                follow proper procedures, including contacting local authorities
                and our emergency assistance number.
              </li>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">8. Prohibited Activities</h2>
            <ul>
              <li>
                The renter and designated driver must refrain from activities that
                impair driving ability, such as alcohol consumption beyond legal
                limits and the use of controlled substances.
              </li>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">9. Termination of Service</h2>
            <p>
              We reserve the right to terminate services if there is a breach of
              terms, unsafe driving practices, or violation of any applicable
              laws.
            </p>
          </section>
        </article>
        <div style={{ marginTop: "20px" }}>
          <p style={{ textAlign: "center", fontStyle: "italic" }}>
            These terms and policies are designed to ensure a safe and enjoyable
            rental experience with Imperial Shuttle. Please review them carefully
            before confirming your reservation.
          </p>
        </div>
      </div>
        <Footer />
    </>
  );
};

export default TermsAndConditions;
