import React, { useEffect, useState, useRef } from "react";
import Loader from "../../Generics/pulsingLoader/Loader";

type OptionValue = string | number;
// Define a discriminated union for Option type
type Option = { id: number; name: OptionValue; [key: string]: any };

interface SearchableMultiSelectProps {
  onSelect: (value: any, pointer: string) => void;
  placeholder?: string;
  label?: string;
  name: string;
  required?: boolean;
  loading?: boolean;
  isResetInputs?: boolean;
  initialOptions: (OptionValue | Option)[];
  initialValue?: string | Option;
}

const SearchableMultiSelect: React.FC<SearchableMultiSelectProps> = ({
  onSelect,
  placeholder,
  label,
  name,
  required,
  loading,
  isResetInputs,
  initialOptions,
  initialValue,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<(OptionValue | Option)[]>([]);
  const [activateDropdown, setActivateDropdown] = useState<boolean>(false);
  const [validInput, setValidInput] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  useEffect(() => {
    if (initialValue) setInputValue(initialValue.toString());
  }, [initialValue]);

  useEffect(() => {
    if (isResetInputs) {
      setInputValue("");
    }
  }, [isResetInputs]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        if (
          inputValue !== "" &&
          !options.some(
            (option) =>
              optionToString(option).toLowerCase() === inputValue.toLowerCase()
          )
        ) {
          setInputValue(""); // Clear input if value not in options
          setValidInput(false);
        }
        setActivateDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [inputValue, options]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setValidInput(true);

    if (value) {
      const filteredOptions = initialOptions?.filter((option) =>
        optionToString(option).toLowerCase().includes(value.toLowerCase())
      );
      setOptions(filteredOptions);
      return;
    }
    setOptions(initialOptions);
  };

  const handleOptionClick = (option: Option | OptionValue) => {
    onSelect(optionToString(option), name);
    setInputValue(optionToString(option));
    setActivateDropdown(false);
    setValidInput(true);
  };

  const optionToString = (option: Option | OptionValue): string => {
    if (typeof option === "object") return option.name.toString();
    return option.toString();
  };

  return (
    <div className="relative flex flex-col justify-start w-full gap-2">
      {label && (
        <span>
          <label className="text-text-primary text-[1rem] font-bold" htmlFor={name}>
            {label}
          </label>
        </span>
      )}
      <div className="relative flex flex-col w-full gap-0">
        <div className="w-full">
          <input
            ref={inputRef}
            className={`w-full px-2 shadow-lg rounded-md py-2 ${
              !validInput ? "border-red-500" : ""
            }`}
            type="text"
            required={required}
            value={inputValue}
            onChange={handleInputChange}
            onClick={() => setActivateDropdown(true)}
            placeholder={placeholder}
          />
        </div>
        {activateDropdown && options?.length > 0 && (
          <ul
            ref={dropdownRef}
            className="w-full bg-white absolute top-11 left-0 right-0 border rounded-lg px-2 py-2 !z-20 h-[35vh] overflow-y-scroll"
          >
            {loading ? (
              <Loader />
            ) : (
              options.map((option, index) => (
                <li className="py-2" key={index} onClick={() => handleOptionClick(option)}>
                  {optionToString(option)}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchableMultiSelect;
