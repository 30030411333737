import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface MessageState {
    message?: {
        description: string
        type: "error" | "warning" | "success"
    }
}

const initialState: MessageState = {
    message: {
        description: "",
        type: "success"
    }
}


export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        messageArrived: (state, action: PayloadAction<{description: string, type: "error" | "warning" | "success"}>) => {
            state.message = action.payload
        },
        messageExpired: (state) => {
            state.message = {
                description: "",
                type: "success"
            }
        },
        resetMessageState: (state) => {
            return initialState;
          },
    },
})

export const { messageArrived, messageExpired, resetMessageState } = messageSlice.actions

export default messageSlice.reducer;
