let baseUrl: string | undefined;

if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
  baseUrl = process.env.REACT_APP_LIVE_BASEURL;
} else {
  baseUrl = process.env.REACT_APP_STAGING_BASEURL;
}
class ApiConstants {
  static baseUrlRest = "/api/v1";
  static baseUrlCarsAPI = `${baseUrl}/api/v1`;
  static apiContactusUrl = ApiConstants.baseUrlRest + "/contactus";
  static apiLoginUrl = ApiConstants.baseUrlRest + "/customers/login";
  static apiRegisterUrl = ApiConstants.baseUrlRest + "/customers/register";
  static apiResetPasswordUrl =
    ApiConstants.baseUrlRest + "/customers/resetpassword";
  static apiForgotPasswordUrl =
    ApiConstants.baseUrlRest + "/customers/forgotpassword";
  static apiActivateAccountUrl =
    ApiConstants.baseUrlRest + "/customer/activate/token/";
  static apiResendActivationCodeUrl =
    ApiConstants.baseUrlRest + "/customer/email/";
  static apiChangePasswordUrl =
    ApiConstants.baseUrlRest + "/customers/changePassword";
  static apiGetVehiclesUrl = ApiConstants.baseUrlRest + "/vehicles?";
  static apiGetVehicleById = ApiConstants.baseUrlRest + "/vehicle/vehicleId/";
  static apiRemoveOder = ApiConstants.baseUrlRest + "/orders/delete/orderId/";
  static apiCreateOrderUrl = ApiConstants.baseUrlRest + "/orders";
  static apiTokenRefreshUrl =
    ApiConstants.baseUrlRest + "/partner/refreshToken/";
  static apiGetOrdersUrl = ApiConstants.baseUrlRest + "/orders/customerId/";
  static apiGetCustomerInvoice = ApiConstants.baseUrlRest + "/invoice/";
  static apiGetInvoice = ApiConstants.baseUrlRest + "/invoice/";
  static apiGetInvoiceByInvoiceNumber =
    ApiConstants.baseUrlRest + "/invoice/invoiceNumber/";
  static apiCreateCustomerInvoiceUrl = ApiConstants.baseUrlRest + "/invoice";
  static apiFilterVehicle = ApiConstants.baseUrlRest + "/vehicle?search?";
  //filters
  static apiGetAllBrands = ApiConstants.baseUrlRest + "/vehicles/brands";
  static apiGetModelByBrandUrl =
    ApiConstants.baseUrlCarsAPI + "/vehicles/models";
  static apiGetYearsUrl = ApiConstants.baseUrlRest + "/vehicles/years";
  static apiGetTypeUrl = ApiConstants.baseUrlRest + "/vehicles/type";
  static apiGetPaymentDetailsUrl = ApiConstants.baseUrlRest + `/transactions/customer/paymentReference/`
  static apiGetPaymentVerifiedUrl = ApiConstants.baseUrlRest + `/transactions/verify/paymentReference/`
}

export default ApiConstants;
