import axios, {Axios, AxiosError, AxiosRequestConfig, AxiosRequestHeaders, Method} from "axios"

export class MyAxios<D> {
    timeout?: number = 10000

    constructor(timeout?: number) {
        this.timeout = timeout
    }

    getAxios(method: Method | undefined, headers?: AxiosRequestHeaders): Axios{
        let options: AxiosRequestConfig = {
            timeout: this.timeout,
            method,
            headers
        }

        return new Axios(options)

    }

    async execute(url: string, method: string, headers?: AxiosRequestHeaders, params?: any,
                    data?: D): Promise<MyAxiosResponse> {
        let resp

        let axiosObj = this.getAxios(method as Method, headers)
        let requestConfig: AxiosRequestConfig<D> = {
            url,
            params: params ?? {},
            data: data ?? undefined,
            transformRequest: axios.defaults.transformRequest
        }
        try {
            let req = axiosObj.request(requestConfig)
            let _resp = await req
            resp = new MyAxiosResponse(_resp)
        }catch (error){
            let axisError = error as AxiosError
            resp = new MyAxiosResponse(null, axisError)
        }
        return resp
    }

}


export class MyAxiosResponse {
    response: any
    axiosError?: AxiosError
    errorMessage?: string

    constructor(response: any, axiosError?: AxiosError, errorMessage?: string) {
        this.response = response
        this.axiosError = axiosError
        this.errorMessage = errorMessage
    }

    get isValid(): boolean {
        let statusCode: string = this.response?.status.toString() ?? ''
        return statusCode.startsWith("2") || statusCode.startsWith("3")
    }

    get error(): string {
        if (this.errorMessage){
            return this.errorMessage
        }
        let errorMessage: string
        let responseData = JSON.parse(this.response.data)
        errorMessage = responseData.message
        return errorMessage
    }

}


export class MyAxiosException extends Error {
    message: string

    constructor(message: string) {
        super(message)
        this.message = message
    }
}
