import { Link, useLocation, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import ModalCartItem from "../ModalCartItem/ModalCartItem";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { activationCompleted, logout } from "../../store/user/userSlice";
import { useEffect, useState } from "react";
import {
  calculateTotalCartPrice,
  formatPrice,
} from "../../utilities/utilities";
import { persistor } from "../../store/store";
import AppMessage from "../AppMessage/AppMessage";

const navigation = [
  { name: "Home", href: "/", current: false },
  { name: "About", href: "/about", current: false },
  { name: "Contact", href: "/contact", current: false },
  { name: "Cars", href: "/car-listing", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = ({ childComp }: { childComp: JSX.Element }) => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const firstName = useAppSelector((state) => state.user.firstName);
  const { vehicles } = useAppSelector((state) => state.app.cart);
  const _vehicles = JSON.stringify(vehicles)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [cost, setCost] = useState<any>([]);

  useEffect(() => {
    const cars = JSON.parse(_vehicles)
    cars.forEach((vehicle: any) => {
      setCost(((prevCost: any)=> ([...prevCost, Number(vehicle?.vehicle?.price)])))
      window.scrollTo(0, 0);
    });
  }, [_vehicles]);

  const handlePrice = () => {
    let newPoints = [...cost];
    let poppedPoints = newPoints.pop();
  };
  return (
    <div className="relative mt-16">
      <Disclosure
        as="nav"
        className="bg-white backdrop-blur-lg shadow-xl top-0 font-open w-full fixed z-50"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto ">
              <div className="relative flex items-center justify-between h-16 z-50">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-1 md:flex-shrink-0 flex-0 lg:flex-1 hidden md:block md:items-center">
                    <Link
                      to="/"
                      className="btn btn-ghost normal-case font-poppins font-normal text-xl"
                    >
                      Imperial <span className="text-sunshine">Shuttle</span>
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 mt-1.5">
                    {location.pathname === "/login" ? (
                      <div className="flex space-x-4">
                        {/* <Link
                          to="/login"
                          className="text-sunshine font-semibold tracking-wider antialiased px-3 py-2 rounded-md text-sm"
                        >
                          Login
                        </Link> */}
                      </div>
                    ) : (
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? " text-sunshine font-semibold tracking-wider antialiased"
                                : "text-gray-500 hover:bg-gray-300 hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex flex-row-reverse md:flex-row items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 !z-50">
                  <div className="flex-none">
                    <div className="dropdown dropdown-end">
                      <label tabIndex={0} className="btn btn-ghost btn-circle">
                        <div className="indicator">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                          {vehicles?.length ? (
                            <span className="h-5 w-5 bg-sunshine flex items-center justify-center rounded-full text-white badge-sm indicator-item">
                              {vehicles?.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </label>
                      <div
                        tabIndex={0}
                        className="mt-3 card card-compact dropdown-content w-96 bg-base-100 shadow"
                      >
                        <div className="card-body">
                          <div className="flex items-center justify-between">
                            <span className="font-bold text-lg">
                              Your Shopping Cart
                            </span>
                            <Link
                              to="/cart"
                              className="underline text-[#e8755b]"
                            >
                              View Cart
                            </Link>
                          </div>
                          {/* start items */}
                          {vehicles?.length ? (
                            <>
                              <div className="max-h-96 overflow-y-scroll w-full pr-2 pb-4 space-y-4">
                                {vehicles?.map((vehicle, idx) => {
                                  return (
                                    <ModalCartItem
                                      vehicle={vehicle}
                                      key={vehicle?.vehicle?._id}
                                      handlePrice={handlePrice}
                                    />
                                  );
                                })}
                              </div>
                              {/* close items */}
                              <div className="flex items-center justify-between text-xl ">
                                <span className="text-black/80">
                                  Estimated Total:{" "}
                                </span>
                                <span>
                                  {formatPrice(
                                    calculateTotalCartPrice(vehicles)
                                  )}
                                </span>
                              </div>
                              <div className="card-actions">
                                <button
                                  onClick={() => navigate("/cart")}
                                  className="btn btn-block !bg-sunshine !border-none"
                                >
                                  Checkout
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="flex w-full h-24 justify-center items-center text-2xl font-semibold">
                              Cart is Empty
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Profile dropdown */}
                  <div className=" dropdown dropdown-end">
                    {isAuthenticated ? (
                      <>
                      <label
                        tabIndex={0}
                        className="btn btn-ghost btn-circle avatar"
                      >
                        <div
                          title={firstName}
                          className="w-10 rounded-full border border-sunshine relative"
                        >
                          {/* <img
                            src="https://api.lorem.space/image/face?hash=33791"
                            alt=""
                          /> */}
                          {firstName && (
                            <p className="absolute top-[35%] left-[40%]">
                              {firstName.charAt(0)}
                            </p>
                          )}
                        </div>
                      </label>
                    <ul
                      tabIndex={0}
                      className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
                    >
                      <li>
                        <Link
                          to="/invoice"
                          className="justify-between active:bg-sunshine"
                        >
                          Invoices
                          <span className="bg-sunshine/20 py-0.5 px-2 text-sunshine font-bold text-xs rounded-box">
                            New
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/orders" className="active:bg-sunshine">
                          Orders
                        </Link>
                      </li>
                    </ul>
                      </>
                    ) : (
                      <label tabIndex={0} className="btn btn-ghost">
                        <Link to="/login" state={{target: "register"}} className="text-gray-500 hover:bg-gray-300 hover:text-white font-semibold tracking-wider antialiased py-2 rounded-md text-sm">

                        Create Account
                      </Link>
                      </label>
                    )}  
                  </div>
                  <div className="mx-10">
                    {isAuthenticated ? (
                      <Link
                        to="/"
                        className="text-sunshine font-semibold tracking-wider antialiased px-3 py-2 rounded-md text-sm"
                        onClick={() => {
                          dispatch(activationCompleted());
                          persistor.purge();
                          dispatch(logout());
                        }}
                      >
                        Logout
                      </Link>
                    ) : (
                      <Link to="/login" className="text-sunshine font-semibold tracking-wider antialiased px-3 py-2 rounded-md text-sm">
                        Login
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="max-w-screen">
      <AppMessage />
        {childComp}
        </div>
    </div>
  );
};

export default Navbar;
