import React, { useEffect, useState } from "react";

type Props = {
  message: string;
};

const Error = ({ message }: Props) => {
  const [error, seterror] = useState(message);

  useEffect(() => {
    const timeoutId = setTimeout(() => seterror(""), 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {error ? (
        <p className="text-sunshine bg-sunshine/10 py-1 px-2 shadow w-fit mx-auto">
          {error}
        </p>
      ) : null}
    </>
  );
};

export default Error;
