import React, { FC } from "react";
import { formatDateTime, formatPrice } from "../../utilities/utilities";

interface Props {
  invoices: any[];
  handleInvoiceView: (id: string) => void;
  firstName?: string;
  lastName?: string;
  isLoading?: boolean;
}

const InvoiceTable: FC<Props> = ({
  invoices,
  firstName,
  lastName,
  isLoading,
  handleInvoiceView,
}) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-gray-400">
          <tr className="text-[1rem]">
            <th></th>
            <th scope="col" className="px-6 py-3">
              INVOICE NUMBER
            </th>
            <th scope="col" className="px-6 py-3">
              CUSTOMER NAME
            </th>
            <th scope="col" className="px-6 py-3">
              PAYMENT REFERENCE
            </th>
            <th scope="col" className="px-6 py-3">
              TOTAL
            </th>
            <th scope="col" className="px-6 py-3">
              CREATED AT
            </th>
            <th scope="col" className="px-6 py-3">
              STATUS
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices &&
            invoices?.map((invoice, index) => {
              return (
                <tr
                  onClick={() => handleInvoiceView(invoice?.invoiceNumber)}
                  key={index}
                  className={`${isLoading ? "cursor-progress" : "cursor-pointer"} bg-white text-[1rem] cursor-pointer border-b hover:bg-gray-50 dark:hover:bg-gray-100`}
                >
                  <th>{index + 1}</th>
                  <td className="px-6 py-2">#{invoice?.invoiceNumber}</td>
                  <td className="px-6 py-2">{`${firstName}`}</td>
                  <td className="px-6 py-2">{invoice?.paymentReference}</td>
                  <td className="px-6 py-2">
                    {formatPrice(invoice?.totalAmountPaid)}
                  </td>
                  <td className="px-6 py-2">
                    {formatDateTime(invoice?.createdAt)}
                  </td>
                  <td className="px-6 py-2">
                    <span className="flex justify-start align-middle items-center space-x-5 pt-2">
                      <span
                        className={`text-white rounded-md p-1 ${
                          invoice?.hasPaid ? "bg-[green]" : "bg-[#ec3323]"
                        }`}
                      >
                        {invoice?.hasPaid === true ? `PAID` : `NOT PAID`}
                      </span>
                      {invoice?.isDeleted && (
                        <span
                          className={`text-white rounded-md p-1 bg-[#ec3323]`}
                        >
                          DELETED
                        </span>
                      )}
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
