import { useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RegisterDetails } from "../../services/auth/interfaces/signup";
import { Form, Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { initialValues } from "./initialState";
import FormikControl from "../Formik/FormikControl";
import { Navigate } from "react-router-dom";
import { register } from "../../store/user/actions";
import Message from "../../views/Message/Message";
import { ClipLoader } from "react-spinners";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { activationSent, status, registerError } = useAppSelector(
    (state: any) => state.user
  );
  const dispatch = useAppDispatch();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (values: any, onSubmitProps: any) => {
    // form data
    const userDetails: RegisterDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      phone: values.phone,
    };
    dispatch(register(userDetails));
    // setIsSubmitting to false
    onSubmitProps.setSubmitting(false);
  };

  return (
    <>
      {activationSent ? (
        <div className="absolute top-[45%] right-0">
          <Message message="Your Activation Code Has Been Sent To Your Email!" />
        </div>
      ) : null}
      {activationSent ? (
        <Navigate to="/account-activation" />
      ) : (
        <div>
          {/* Register Section */}
          <div className="my-8">
            {/* Register Title */}
            <h1 className="text-2xl font-semibold tracking-wide antialiased">
              Don't have an Account? Register Now
            </h1>
          </div>

          {/* Register Section */}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {(formik: any) => {
              return (
                <Form className="w-full px-2">
                  <div className="my-8 grid grid-cols-1 md:grid-cols-3 items-center gap-8 relative">
                    {/* Full Name */}
                    <div className="flex flex-col justify-start space-y-1 relative">
                      <FormikControl
                        control="input"
                        type="text"
                        name="firstName"
                        width="small"
                        label="First Name"
                      />
                    </div>
                    {/* UserName */}
                    <div className="flex flex-col justify-start">
                      <FormikControl
                        control="input"
                        type="text"
                        name="lastName"
                        width="small"
                        label="Last Name"
                      />
                    </div>
                    {/* Password */}
                    <div className="flex flex-col justify-start space-y-1 relative">
                      <FormikControl
                        control="input"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        width="small"
                        label="Password"
                      />
                      <span
                        className="absolute top-3 right-5 cursor-pointer z-10"
                        onClick={handleTogglePassword} >
                        {showPassword ? (
                          <AiOutlineEyeInvisible size={20} />
                        ) : (
                          <AiOutlineEye size={20} />
                        )}
                      </span>
                    </div>

                    {/* Email */}
                    <div className="flex flex-col justify-start space-y-1 relative">
                      <FormikControl
                        control="input"
                        type="text"
                        name="email"
                        width="small"
                        label="Email"
                      />
                    </div>
                    {/* Phone */}
                    <div className="flex flex-col justify-start space-y-1 relative">
                      <FormikControl
                        control="input"
                        type="text"
                        name="phone"
                        width="small"
                        label="Phone"
                      />
                    </div>

                    <div className="flex flex-col justify-start space-y-1 relative">
                      <FormikControl
                        control="input"
                        type="password"
                        name="confirmPassword"
                        width="small"
                        label="Confirm Password"
                      />
                    </div>
                  </div>
                  <button className="relative w-full md:w-1/5 mx-auto text-center py-3 px-4 mt-4 font-medium hover:shadow-lg text-white bg-sunshine focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sunshine">
                    {!registerError && status === "loading" ? (
                      <ClipLoader color="white" size={20} />
                    ) : (
                      <span>REGISTER</span>
                    )}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default Register;
