import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VehiclesService } from "../../services/vehicles/VehiclesService";
import { Cart } from "./interfaces/cart";

export interface AppState {
  cart: Cart;
  status: "idle" | "loading" | "failed";
}

const initialState: AppState = {
  status: "idle",
  cart: {
    vehicles: [],
    invoices: [],
    invoice: {},
    viewInvoice: {},
  },
};


export const removeVehicleFromCart = createAsyncThunk(
  "vehicles/removeVehicleFromCart",
  async (vehicleId: string) => {
    const data = await VehiclesService.removeVehicleFromCart(vehicleId)
    return data
  }
);

export const getCustomerInvoice = createAsyncThunk(
  "vehicles/getCustomerInvoice",
  async (customerId: string) => {
    const data = await VehiclesService.getCustomerInvoice(customerId)
    return data
  }
);

export const getInvoiceById = createAsyncThunk(
  "vehicles/getInvoiceById",
  async (invoiceId: string) => {
    const data = await VehiclesService.getInvoiceById(invoiceId)
    return data
  }
);

export const getInvoiceByInvoiceNumber = createAsyncThunk(
  "vehicles/getInvoiceByInvoiceNumber",
  async (invoiceNumber: string) => {
    const data = await VehiclesService.getInvoiceByInvoiceNumber(invoiceNumber)
    return data
  }
);


export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cart.vehicles.push(action?.payload);
    },
    emptyCart: (state, action) => {
      state.cart.vehicles = action?.payload
    },
    removeFromCart: (state, action) => {
      state.cart.vehicles = state?.cart?.vehicles?.filter(
        (vehicle) => vehicle?.vehicle._id !== action?.payload
      );
    },
    setCustomerInvoice: (state, action) => {
      state.cart.invoices = action?.payload
    },
  }, 
  extraReducers: (builder) => {
    builder
      .addCase(removeVehicleFromCart.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(removeVehicleFromCart.fulfilled, (state, action) => {
        // state.status = "idle";
        // state.cart.vehicles = action.payload;
        // state.fetched = true
      })
      .addCase(getCustomerInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomerInvoice.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart.invoices = action?.payload?.docs;
        // state.fetched = true
      })
      .addCase(getInvoiceByInvoiceNumber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInvoiceByInvoiceNumber.fulfilled, (state, action) => {
        state.status = "idle";
        state.cart.viewInvoice = action?.payload;
        // state.fetched = true
      })
  },
});

export const { addToCart, removeFromCart, setCustomerInvoice, emptyCart } =
  appSlice.actions;

export default appSlice.reducer;
