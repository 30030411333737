import { useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./views/About/About";
import CarBooking from "./views/CarBooking/CarBooking";
import CarListing from "./views/CarListings/CarListing";
import Contact from "./views/Contact/Contact";
import Home from "./views/Home/Home";
import Orders from "./views/Orders/Orders";
import Login from "./views/Login";
import ProtectedRoute from "./routes/protectedRoute";
import Navbar from "./components/Nav/Navbar";
import Cart from "./views/Cart/Cart";
import Checkout from "./views/Checkout/Checkout";
import CarDetails from "./views/CarDetails/CarDetails";
import AccountsActivation from "./views/AccountsActivation/AccountsActivation";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import Invoice from "./views/Invoice/Invoice";
import SingleInvoice from "./views/Invoice/SingleInvoice";
import OrderDetails from "./views/Orders/OrderDetails";
import { logout } from "./store/user/userSlice";
import { useAppDispatch } from "./store/hooks";
import AuthVerify from "./routes/AuthVerify";
import { persistor } from "./store/store";
import TermsAndConditions from "./views/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import PaymentConfirm from "./views/Payment/PaymentConfirm";

function App() {
  const dispatch = useAppDispatch();

  const logOut = useCallback(() => {
    // clear the persisted store
    persistor.purge();
    dispatch(logout());
  }, [dispatch]);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Navbar childComp={<Home />} />} />
          <Route path="/about" element={<Navbar childComp={<About />} />} />
          <Route path="/contact" element={<Navbar childComp={<Contact />} />} />
          <Route path="/terms-and-conditions" element={<Navbar childComp={<TermsAndConditions />} />} />
          <Route path="/privacy-policy" element={<Navbar childComp={<PrivacyPolicy />} />} />
          <Route path="/login" element={<Navbar childComp={<Login />} />} />
          <Route
            path="/car-listing"
            element={<Navbar childComp={<CarListing />} />}
          />
          <Route path="/cart" element={<ProtectedRoute />}>
            <Route path="/cart" element={<Navbar childComp={<Cart />} />} />
          </Route>

          <Route path="/invoice" element={<ProtectedRoute />}>
            <Route
              path="/invoice"
              element={<Navbar childComp={<Invoice />} />}
            />
          </Route>
          <Route path="/invoice/:invoiceID" element={<ProtectedRoute />}>
            <Route
              path="/invoice/:invoiceID"
              element={<Navbar childComp={<SingleInvoice />} />}
            />
          </Route>

          <Route path="/car-booking/:vehicleID" element={<ProtectedRoute />}>
            <Route
              path="/car-booking/:vehicleID"
              element={<Navbar childComp={<CarBooking />} />}
            />
          </Route>

          <Route
            path="/car-details/:vehicleID"
            element={<Navbar childComp={<CarDetails />} />}
          />
          <Route path="/cart/checkout" element={<ProtectedRoute />}>
            <Route
              path="/cart/checkout"
              element={<Navbar childComp={<Checkout />} />}
            />
          </Route>
          <Route path="/orders" element={<ProtectedRoute />}>
            <Route path="/orders" element={<Navbar childComp={<Orders />} />} />
          </Route>
          <Route path="/orders/:orderID" element={<ProtectedRoute />}>
            <Route
              path="/orders/:orderID"
              element={<Navbar childComp={<OrderDetails />} />}
            />
          </Route>
          <Route path="/account-activation" element={<AccountsActivation />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/confirm-payment" element={<PaymentConfirm/>} />
        </Routes>
        <AuthVerify logOut={logOut} />
      </Router>
    </div>
  );
}

export default App;
