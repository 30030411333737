import React, { FC } from "react";
import { formatPrice } from "../../../utilities/utilities";

interface Props {
  totalAmountPaid?: number;
}

const Footer: FC<Props> = ({ totalAmountPaid }) => {
  return (
    <div className="py-2 ml-auto mt-5w-[100%]  sm:w-2/4 lg:w-1/4">
      <div className="py-2">
        <div className="flex justify-between">
          <div className="text-xl text-gray-600 text-right flex-1">
            Amount paid
          </div>
          <div className="text-right w-40">
            <div
              className="text-xl text-gray-800 font-bold pr-5"
              x-html="netTotal"
            >
              {totalAmountPaid && formatPrice(totalAmountPaid)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
