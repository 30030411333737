import React from "react";
import Banner from "../../components/Banner/Banner";
import { ImLocation } from "react-icons/im";
import { FiPhoneCall } from "react-icons/fi";
import { BiMailSend } from "react-icons/bi";
import Map from "../../components/Map/Map";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import FormikControl from "../../components/Formik/FormikControl";
import { Form, Formik } from "formik";
import { initialValues } from "./Form/initialState";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { validationSchema } from "./Form/validationSchema";
import { contactUs } from "../../store/user/actions";
import Message from "../Message/Message";
const Contact = () => {
  const dispatch = useAppDispatch();
  const { successMessage } = useAppSelector((state) => state.user);
  const onSubmit = (values: any, onSubmitProps: any) => {
    // form data
    const userDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      message: values.message,
      phone: values.phone,
    };
    dispatch(contactUs(userDetails));
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
  };
  return (
    <div>
      <Banner title="Contact" />
      <div className="pt-16 pb-20 bg-[#f2f2f2] w-full relative">
        <div className="absolute breadcrumbs text-sm antialiased w-fit z-10 -top-6 left-[50%] translate-x-[-50%] px-4 py-3 rounded-lg shadow-xl shadow-sunshine/40 bg-sunshine !text-white">
          <ul>
            <li>
              <Link to="/" className="text-white">
                Home
              </Link>
            </li>

            <li>
              <Link to={`/contact`}>Contact</Link>
            </li>
          </ul>
        </div>
        <div className="max-w-7xl mx-auto">
          <h4 className="uppercase my-3 text-center text-xl text-[#ec3323] ">
            get in touch
          </h4>
          <h2 className="my-3 text-center text-3xl font-bold uppercase">
            contact info
          </h2>
          <p className="my-3 text-center text-[#797979] md:w-1/2 mx-auto px-4"></p>
          <div className="md:flex md:gap-8 md:justify-between items-center mt-6 space-y-4 md:px-8 md:space-y-0 flex-wrap">
            {/* address */}
            <div
              className="rounded-lg bg-white mx-4 md:mx-auto md:w-[24rem] hover:bg-[#ec3323] py-12 px-10 group hover:text-white transition ease-in-out duration-300"
              style={{ boxShadow: "0px 0 8px rgb(0 0 0 / 10%" }}
            >
              <div className="flex gap-3 items-end">
                <ImLocation className="text-[#ec3323] text-3xl group-hover:text-white transition ease-in-out duration-300" />
                <div className="font-semibold text-xl uppercase leading-10 ">
                  Address
                </div>
              </div>
              <div className="text-[#797979] text-lg ml-10 group-hover:text-white transition ease-in-out duration-300">
                House No S1, Hydraform Est., Off Spintex Road, East Airport,
                Accra.<br /><br />
              </div>
            </div>
            <div
              className="rounded-lg bg-white mx-4 md:mx-auto md:w-[24rem] hover:bg-[#ec3323] py-12 px-10 group hover:text-white transition ease-in-out duration-300"
              style={{ boxShadow: "0px 0 8px rgb(0 0 0 / 10%" }}
            >
              {/* contact number */}
              <div className="flex gap-3 items-end">
                <FiPhoneCall className="text-[#ec3323] text-3xl group-hover:text-white transition ease-in-out duration-300 " />
                <div className="font-semibold text-xl uppercase leading-10 ">
                  Phone Number
                </div>
              </div>
              <div className="text-[#797979] text-lg ml-10 group-hover:text-white transition ease-in-out duration-300">
              +233 50 636 9101 <br />
              +233 20 815 4869 <br />
              +233 24 656 6990
              </div>
            </div>
            {/* email */}
            <div
              className="rounded-lg bg-white mx-4 md:mx-auto md:w-[24rem] hover:bg-[#ec3323] py-12 px-10 group hover:text-white transition ease-in-out duration-300"
              style={{ boxShadow: "0px 0 8px rgb(0 0 0 / 10%" }}
            >
              <div className="flex gap-3 items-end">
                <BiMailSend className="text-[#ec3323] text-3xl group-hover:text-white transition ease-in-out duration-300" />
                <div className="font-semibold text-xl uppercase leading-10 ">
                  Email
                </div>
              </div>
              <div className="text-[#797979] text-lg ml-10 group-hover:text-white transition ease-in-out duration-300">
              <a href="mailto:support@imperialshuttle-gh.com">support@imperialshuttle-gh.com</a><br /><br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* map */}
      <div className="w-full h-[40rem]">
        <Map />
      </div>
      {/* contact-info */}
      {/* <div className="w-full">
        <div className="max-w-5xl mx-auto my-16">
          <h2 className="my-3 text-center text-3xl font-bold uppercase">
            leave a message
          </h2>
          <p className="my-3 text-center text-[#797979] md:w-1/2 mx-auto px-4"></p>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            {(formik: any) => {
              return (
                <Form className="w-full px-2">
                  <div className="mt-6 max-w-4xl md:mx-8 lg:mx-auto mx-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-8 flex-wrap relative">
                      <div className="flex flex-col justify-start space-y-1 relative">
                        <FormikControl
                          control="ContactInput"
                          type="text"
                          name="firstName"
                          width="small"
                          label="First Name *"
                        />
                      </div>
                      <div className="flex flex-col justify-start">
                        <FormikControl
                          control="ContactInput"
                          type="text"
                          name="lastName"
                          width="small"
                          label="Last Name *"
                        />
                      </div>
                      <div className="flex flex-col justify-start space-y-1 relative">
                        <FormikControl
                          control="ContactInput"
                          type="email"
                          name="email"
                          width="small"
                          label="Email *"
                        />
                      </div>
                      <div className="flex flex-col justify-start space-y-1 relative">
                        <FormikControl
                          control="ContactInput"
                          type="text"
                          name="phone"
                          width="small"
                          label="Phone *"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col justify-start space-y-1 mt-4 relative">
                      <FormikControl
                        control="ContactTextarea"
                        type="textarea"
                        name="message"
                        width="small"
                        label="Write here..."
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                      <button
                        disabled={formik.isSubmitting}
                        className="bg-[#ec3323] text-white border-none shadow-lg rounded-md outline-none my-4 text-center mx-auto py-3 px-6 border hover:scale-110 transition ease-in-out duration-300 delay-150"
                      >
                        Send Message
                      </button>
                      {successMessage && <Message message={successMessage} />}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div> */}
      <div className="w-full py-5"></div>
      <Footer />
    </div>
  );
};

export default Contact;
