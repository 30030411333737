import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUtils } from "../../services/http/api/rest";
import { appSingleton } from "../../configuration/appUtils";
import ApiConstants from "../../configuration/constants";
import {
  LoginDetails,
  PasswordResetDetails,
} from "../../services/auth/interfaces/login";
import {
  ContactusDetails,
  RegisterDetails,
} from "../../services/auth/interfaces/signup";
import HttpConstants from "../../services/http/constants";
import { messageArrived } from "../message/messageSlice";

export const login = createAsyncThunk(
  "user/login",
  async (credentials: LoginDetails, { rejectWithValue, dispatch }) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiLoginUrl,
      data: {
        email: credentials.email,
        password: credentials.password,
      },
    });
    if (!response?.isValid) {
      dispatch(
        messageArrived({ description: response?.message ?? "Please check you credentials and try again!", type: "error" })
      );
      return rejectWithValue(response?.message);
    }
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (credentials: PasswordResetDetails) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiResetPasswordUrl,
      data: {
        token: credentials.token,
        password: credentials.password,
      },
    });
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email: string) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiForgotPasswordUrl,
      data: {
        email: email,
      },
    });
    return response;
  }
);

export const completeAccountActivationAndlogin = createAsyncThunk(
  "user/completeAccountActivationAndlogin",
  async (activateToken: string) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiActivateAccountUrl + activateToken,
      method: HttpConstants.KEY_POST,
    });
    localStorage.clear()
    return response;
  }
);
export const resendActivationActivationCode = createAsyncThunk(
  "user/resendActivationActivationCode",
  async (email: string) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiResendActivationCodeUrl + email + "/resendCode",
      method: HttpConstants.KEY_POST,
    });
    return response;
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (details: RegisterDetails, { rejectWithValue, dispatch }) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiRegisterUrl,
      data: {
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        password: details.password,
        phoneNumber: details.phone,
      },
    });
    if (!response?.isValid) {
      dispatch(
        messageArrived({ description: response?.message ?? "Please check you details and try again!", type: "error" })
      );
      return rejectWithValue(response?.message);
    }
    return response?.rawBody;
  }
);

export const contactUs = createAsyncThunk(
  "user/contactUs",
  async (details: ContactusDetails) => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiContactusUrl,
      data: {
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        phoneNumber: details.phone,
        message: details.message,
      },
    });
    return response?.rawBody;
  }
);

export const getCustomerDetails = createAsyncThunk(
  "user/getCustomerDetails",
  async () => {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiRegisterUrl,
    });
    return response?.rawBody;
  }
);
