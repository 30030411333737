import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import { buildInitialVehicleDetails } from "../../services/vehicles/interfaces/createVehicleDetails";
import { OrderDetails } from "../../services/vehicles/interfaces/orderDetails";
import { VehiclesService } from "../../services/vehicles/VehiclesService";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Formik, Form } from "formik";
import { ClipLoader } from "react-spinners";
import FormikControl from "../../components/Formik/FormikControl";
import { validationSchema } from "./validationSchema";
import { addToCart } from "../../store/app/appSlice";
import Message from "../Message/Message";
import GoogleLocationPicker from "../../components/GoogleLocationPicker/GoogleLocationPicker";
import { formatDateTime, formatPascalCase } from "../../utilities/utilities";
import Datepicker from "../../components/Formik/fields/Datepicker";

interface FormValues {
  startDate: string;
  endDate: string;
  pickUpLocation: string;
  dropOffLocation: string;
  note: string;
}
const CarBooking = () => {
  const navigate = useNavigate();
  const targetRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  let selectedVehicle = location?.state;
  const [vehicle, setVehicle] = useState(buildInitialVehicleDetails());
  const [authorizationUrl, setAuthorizationUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const [pickUpLocation, setPickUpLocation] = useState<string>("");
  const [dropOffLocation, setDropOffLocation] = useState<string>("");
  const userState = useAppSelector((state) => state.user);
  const initialFormValues: FormValues = {
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
    pickUpLocation: "",
    note: "",
    dropOffLocation: "",
  };
  let excludedVehicleProps = [
    "_id",
    "createdAt",
    "updatedAt",
    "insuranceNumber",
    "purpose",
    "__v",
  ];
  let { vehicleID } = useParams();
  useEffect(() => {
    if (vehicleID) {
      VehiclesService.getVehicleById(vehicleID).then((vehicle) => {
        setVehicle((prev) => (prev = vehicle));
      });
    }
  }, [vehicleID]);

  const bookVehicleAction = async (formValues: FormValues) => {
    setIsLoading(true);
    if (userState.customerID) {
      const orderDetails: OrderDetails = {
        customer: userState?.customerID,
        vehicle: vehicle?._id,
        isDriverInclusive: true,
        note: formValues.note,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        address: {
          dropOff: formValues.dropOffLocation,
          pickUp: formValues.pickUpLocation,
        },
      };
      const data = await VehiclesService.bookVehicle(orderDetails);

      return data;
    }
  };

  const handleError = (msg: string) => {
    setIsLoading(false);
    setNotification(msg);
    setTimeout(() => {
      setNotification("");
      return;
    }, 2000);
  };

  const hasPickupDrop = Boolean(pickUpLocation) && Boolean(dropOffLocation);

  return (
    <div>
      <Banner title="Car Booking" />
      {/* Car Features */}
      <div className="py-8 bg-[#fbfbfb] relative">
        {/* notification */}
        <div className="absolute right-0 mr-[5rem]">
          {notification && <Message message={notification} />}
        </div>
        <div className="absolute breadcrumbs text-sm antialiased w-fit z-10 -top-6 left-[50%] translate-x-[-50%] px-4 py-3 rounded-lg shadow-xl shadow-sunshine/40 bg-sunshine !text-white">
          <ul>
            <li>
              <Link to="/" className="text-white">
                Home
              </Link>
            </li>

            <li>
              <Link to={`/car-details/${vehicleID}`}>Vehicle Details</Link>
            </li>
            <li>
              <Link to={`/car-booking/${vehicleID}`}>Vehicle Booking</Link>
            </li>
          </ul>
        </div>
        <div className="md:my-7  max-w-6xl md:mx-auto">
          <div className="flex gap-4 flex-col md:flex-row">
            {/* Image */}
            <div className="w-full">
              {/* TODO Implement carousel for images */}
              <img
                src={`${
                  vehicle.images[0]
                    ? vehicle.images[0]
                    : "/assets/images/default-car.webp"
                }`}
                alt="Listing"
              />
              <button
              onClick={() => targetRef?.current.scrollIntoView({ behavior: 'smooth' })}
                type="submit"
                className="cursor-pointer lg:mx-auto my-6 p-3 bg-[#ec3323] w-[90%] mx-3  lg:w-1/2 text-center text-xl text-white font-semibold hover:shadow-lg hover:scale-105 rounded"
              >
                Enter Booking Details
              </button>
            </div>
            {/* rental */}
            <div className="w-full px-3 md:px-0">
              <button className="bg-[#ec3323] mt-4 md:mt-0 py-1 px-5 text-center text-white rounded-sm font-semibold">
                Rental
              </button>
              <div className="font-bold text-3xl my-3">{vehicle.brand}</div>
              {/* rate and ratings */}
              <div className="flex gap-2">
                {/* Rate */}
                <div className="font-semibold">
                  GHS {vehicle.price}{" "}
                  <span className="text-[#ec3323]">/ Day</span>
                </div>
                {/* Rating */}
                <div className="flex items-center">
                  <svg
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    className="w-5 h-5 text-gray-300 dark:text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                    4.95 out of 5
                  </p>
                </div>
              </div>
              <div className="text-left my-4 text-[#88939e]">
                <div className="grid md:grid-cols-3 grid-cols-2 gap-5">
                  {Object.entries(vehicle)
                    .filter((prop) => {
                      return !excludedVehicleProps.includes(prop[0]);
                    })
                    .map((prop) => {
                      return (
                        typeof prop[1] != "object" && (
                          <div
                            key={prop[0]}
                            className="flex justify-start gap-2 flex-wrap"
                          >
                            <div className="font-bold">{`${formatPascalCase(
                              prop[0]
                            )}`}</div>{" "}
                            :
                            <div>{`${
                              prop[1] === true
                                ? "Yes"
                                : prop[1] === false
                                ? "No"
                                : formatDateTime(prop[1])
                            }`}</div>
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Form, Personal Details*/}

      <div ref={targetRef} className="bg-white">
        {/* form */}
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            // const newValues = { ...values, pickUpLocation, dropOffLocation };
            bookVehicleAction(values).then((res) => {
              actions.setSubmitting(false);
              if (res?.msg !== "Order created successfully") {
                handleError("This vehicle is currently not available");
                return;
              }
              if (res === "This vehicle is currently booked") {
                handleError("This vehicle is currently booked");
                return;
              }
              if (
                res !== undefined ||
                res !== "This vehicle is currently booked"
              ) {
                setIsLoading(false);
                let orderedVehicle = {
                  vehicle: selectedVehicle,
                  details: res,
                };
                dispatch(addToCart(orderedVehicle));
                navigate(`/cart`);
              }
            });
          }}
        >
          <Form>
            <div className="max-w-6xl md:flex justify-between md:my-7 md:mx-auto">
              {/* left-Side */}
              <div className="md:w-8/12 mx-4 md:mx-auto">
                <h2 className=" text-2xl font-bold tracking-wide">
                  Booking Details
                </h2>
                <hr className="border-[#f3f3f3] my-3" />
                <div className="md:grid grid-cols-2">
                  <label className="font-semibold">Book From</label>
                  <div className="w-full mt-0 mb-4- px-0 py-1">
                    <FormikControl
                      control="date"
                      type="date"
                      name="startDate"
                      width="small"
                      label="Start Date"
                    />
                  </div>
                  <label className="font-semibold mt-4">Return Date</label>
                  <div className=" w-full mt-4 mb-4 px-0 py-1">
                    <FormikControl
                      control="date"
                      type="date"
                      name="endDate"
                      width="small"
                      label="Return Date"
                    />
                  </div>
                  <label className="font-semibold">Pick-up Location</label>
                  <div className=" w-full mt-4 mb-4 px-0 py-1">
                    <FormikControl
                      control="input"
                      type="text"
                      name="pickUpLocation"
                      width="small"
                      label="Pick-up Location"
                    />
                  </div>
                  <label className="font-semibold">Drop-off Location</label>
                  <div className=" w-full mt-4 mb-4 px-0 py-1">
                    <FormikControl
                      control="input"
                      type="text"
                      name="dropOffLocation"
                      width="small"
                      label="Drop-off Location"
                    />
                  </div>
                  {/* <label className="font-semibold">Pick-up Location</label>
                  <div className=" w-full mt-0 mb-4 px-0 py-1">
                    <GoogleLocationPicker
                      placeholder="Pick-up Location"
                      setLocation={setPickUpLocation}
                    />
                  </div>
                  <label className="font-semibold">Drop-off Location</label>
                  <div className=" w-full mt-0 mb-4 px-0 py-1">
                    <GoogleLocationPicker
                      placeholder="Drop-off Location"
                      setLocation={setDropOffLocation}
                    />
                  </div> */}
                </div>
                {/* CK Editor */}
                <label className="font-semibold">Comments</label>
                <div className=" w-full mt-4 mb-4 px-0 py-1">
                  <FormikControl
                    control="ContactTextarea"
                    type="textarea"
                    name="note"
                    width="small"
                    label="Write your comments here..."
                  />
                </div>
                <div className="text-center flex justify-start flex-col items-center">
                  {/* {!hasPickupDrop ? (
                    <div className="text-[#ec3323]">
                      Please Provide Pickup and Drop off Location!
                    </div>
                  ) : (
                  )} */}
                  <button
                    type="submit"
                    className="cursor-pointer mx-auto my-6 p-3 bg-[#ec3323] w-[200px] text-center text-xl text-white font-semibold hover:shadow-lg hover:scale-105 rounded"
                  >
                    {isLoading ? (
                      <ClipLoader color="white" size={20} />
                    ) : (
                      <div className="text-center m-auto">Book Now</div>
                    )}
                  </button>
                  {/* notification */}
                  <div className="">
                    {notification && <Message message={notification} />}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      <div
        id="defaultModal"
        tabIndex={-1}
        aria-hidden="true"
        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
      >
        {!userState.isAuthenticated ? (
          <Navigate to={`/login?next=/car-booking/${vehicleID}`} />
        ) : (
          <div className="relative md:p-12 w-full max-w-2xl h-full overflow-hidden">
            <div className="relative bg-white rounded-lg shadow w-full h-full">
              <div className="flex justify-between items-start p-5 rounded-t border-b ">
                <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
                  Payment
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {authorizationUrl && (
                <iframe
                  className="w-full h-full md:scale-105"
                  src="https://checkout.paystack.com/t4jb4398ncggh9y"
                  frameBorder="0"
                  title="Payment"
                ></iframe>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CarBooking;
