import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { OrderService } from "../../services/orders/OrderService";
import { VehiclesService } from "../../services/vehicles/VehiclesService";
import {
  emptyCart,
  getCustomerInvoice,
} from "../../store/app/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getMatchedResults,
} from "../../utilities/utilities";
import "./Invoice.css";
import InvoiceTable from "./InvoiceTable";

const Invoice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { invoices } = useAppSelector((state) => state.app.cart);
  const { firstName: fName, lastName: lName } = useAppSelector(
    (state) => state.user
  );

  const [firstName, setFirstName] = useState(fName);
  const [lastName, setLastName] = useState(lName);
  const [isLoading, setIsLoading] = useState(false);
  const customerID = useAppSelector((state) => state.user.customerID);

  useEffect(() => {
    let isCancelled = false;
    let userfName = localStorage.getItem("firstName");
    let userlName = localStorage.getItem("lastName");
    if (userfName) setFirstName(userfName);
    if (userlName) setLastName(userlName);
    if (!isCancelled) {
      dispatch(getCustomerInvoice(customerID));
      dispatch(emptyCart([]));
    }

    return () => {
      isCancelled = true;
    };
  }, [customerID, dispatch]);

  const handleInvoiceView = (invoiceNumber: string) => {
    if (customerID) {
      setIsLoading(true);
      OrderService.getOrders(customerID)
        .then((orders) => {
          if (orders !== undefined) {
            VehiclesService.getInvoiceByInvoiceNumber(invoiceNumber)
              .then((invoiceRes) => {
                setIsLoading(false);
                if (invoiceRes && invoiceRes?.orders?.length > 0) {
                  let invoiceOrders = getMatchedResults(
                    orders,
                    invoiceRes?.orders
                  );
                  navigate(`/invoice/${invoiceNumber}`, {
                    state: invoiceOrders,
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
              });
          } 
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className=" max-w-[77%] mx-auto h-fit px-4 py-12 bg-white text-black relative">
        {/* Heading */}
        <div className="my-12">
          <h1 className="text-xl font-medium tracking-wide ">
            List of invoices
          </h1>
          {/* BreadCrumbs */}
          <div className="breadcrumbs text-sm antialiased">
            <ul>
              <li>
                <Link to="/" className="text-gray-500">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/invoice">invoice</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Login section */}
        <div>
          {/* Login Heading */}
          <div className="my-8">
            <h1 className="text-md font-semibold tracking-wide antialiased">
              Your invoices
            </h1>
          </div>

          {/* invoices */}
          <div className="overflow-x-auto">
            <InvoiceTable
              isLoading={isLoading}
              handleInvoiceView={handleInvoiceView}
              firstName={firstName}
              lastName={lastName}
              invoices={invoices}
            />
          </div>

          {/* cart buttons */}
          <div className=" w-full flex  justify-between my-12 shopping_button">
            <div className="flex gap-10 shopping_button"></div>
            <button
              onClick={() => {
                navigate("/car-listing");
              }}
            >
              CONTINUE SHOPPING
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Invoice;
