const data = [
  "We are a trusted name",
  "We deal in have all brands",
  "have a larger stock of vehicles",
  "we are at worldwide locations",
];

export const carType = [
  "Sedan",
  "SUV",
  "Mini Van",
  "Trucks",
];

export const peopleLikes = [
  {
    title: "Reliability",
    description:
      "Count on us for punctual and dependable service, ensuring you reach your destination on time, every time.",
  },
  {
    title: "Professional Drivers",
    description:
      "Our drivers are not just behind the wheel; they're experienced professionals dedicated to your safety and comfort.",
  },
  {
    title: "Fleet Quality",
    description:
      "Enjoy the ride in our well-maintained and diverse fleet of vehicles, offering a range of options to suit your preferences",
  },
  {
    title: "Customer-Centric Approach",
    description:
      "Your satisfaction is our priority. We listen to your needs and strive to exceed your expectations with personalized service."
  },
  {
    title: "Seamless Booking",
    description:
      "Experience the convenience of easy booking through our user-friendly app, putting you in control of your travel plans.",
  },
  {
    title: "Fair Pricing",
    description:
      "Transparent and competitive pricing ensures you get value for your money without any hidden fees.",
  },
];

export default data;
