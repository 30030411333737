import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import Loader from "../Generics/pulsingLoader/Loader";
import "./Invoice.css";
import Description from "./Sections/Description";
import Header from "./Sections/Header";
import Meta from "./Sections/Meta";

const SingleInvoice = () => {
  const location = useLocation();
  let orders = location?.state as any;

  const { viewInvoice } = useAppSelector((state) => state.app.cart);


  const { status } = useAppSelector((state) => state.app);
  const { firstName: fName, lastName: lName } = useAppSelector(
    (state) => state.user
  );
  const [firstName, setFirstName] = useState(fName);
  const [lastName, setLastName] = useState(lName);

  const printInvoiceHandler = () => {
    window.print();
  };


  return (
    <div className="relative">
      {status === "loading" ? (
        <div className="absolute top-[50%] right-[50%]">
          <Loader />
        </div>
      ) : (
        <>
          <div className="border-t-8 border-gray-700 h-2"></div>
          <div className="w-[100%] mx-auto py-6 px-4">
            <Header printInvoiceHandler={printInvoiceHandler} />
            <Meta
              customer={`${firstName} ${lastName}`}
              invoiceDate={viewInvoice?.createdAt}
              invoiceNumber={viewInvoice?.invoiceNumber}
            />
            <Description orders={orders} />
          </div>
        </>
      )}
    </div>
  );
};

export default SingleInvoice;
