import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaymentDetails } from "../../services/payment/interfaces/createPaymentDetails";
import { PaymentService } from "../../services/payment/PaymentService";

export interface PaymentState {
  paymentDetails: PaymentDetails | undefined;
  status: "idle" | "loading" | "failed";
  fetched: boolean;
  noInternet: boolean;
}

const initialState: PaymentState = {
  paymentDetails: undefined,
  status: "idle",
  fetched: false,
  noInternet: false,
};

export const getPaymentDetails = createAsyncThunk(
  "paymentDetails/getPaymentDetails",
  async (_id: string) => {
    const response = await PaymentService.getPaymentDetailsById(_id);
    return response?.rawBody?.data;
  }
);

export const verifyPayment = createAsyncThunk(
  "paymentDetails/getPaymentDetails",
  async (_id: string) => {
    const response = await PaymentService.verifyPaymentById(_id);
    return response;
  }
);

export const paymentDetailSlice = createSlice({
  name: "paymentDetail",
  initialState,
  reducers: {
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
    setNoInternet: (state, action: PayloadAction<boolean>) => {
      state.noInternet = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPaymentDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.paymentDetails = action.payload;
        state.fetched = true;
      })
      .addCase(getPaymentDetails.rejected, (state, action) => {
        state.status = "idle";
      })
    }
});
export const {
    setPaymentDetails,
    setNoInternet,
} = paymentDetailSlice.actions;

export default paymentDetailSlice.reducer;

