import React from "react";
import {
  GiGearStickPattern,
  GiPathDistance,
  GiSteeringWheel,
} from "react-icons/gi";
import { RiGasStationLine } from "react-icons/ri";
import { IoMdCar } from "react-icons/io";

// import SkeletonCard from "./SkeletonCard";
import { VehicleDetails } from "../../services/vehicles/interfaces/createVehicleDetails";
import { MdEventSeat } from "react-icons/md";
import { FaFan } from "react-icons/fa";
import { Link } from "react-router-dom";

const Tabs = ({
  color,
  vehicle,
}: {
  color: string;
  vehicle: VehicleDetails;
}) => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap mx-2">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "xl:text-xl font-bold uppercase lg:px-5 px-3 lg:py-3 py-2 shadow-lg rounded-3xl block leading-normal " +
                  (openTab === 1
                    ? `text-white bg-${color}`
                    : `text-${color} bg-white`)
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                All Specifications
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "xl:text-xl font-bold uppercase lg:px-5 px-3 lg:py-3 py-2 shadow-lg rounded-3xl block leading-normal " +
                  (openTab === 2
                    ? `text-white bg-${color}`
                    : `text-${color} bg-white`)
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Image Gallery
              </a>
            </li>
            <li className="mt-4 md:mt-0 -mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "xl:text-xl font-bold uppercase lg:px-5 px-3 lg:py-3 py-2 shadow-lg rounded-3xl block leading-normal " +
                  (openTab === 3
                    ? `text-white bg-${color}`
                    : `text-${color} bg-white`)
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Rental Terms
              </a>
            </li>
          </ul>
          {vehicle ? (
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full my-6 shadow-lg rounded-3xl">
              <div className="px-8 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <div>
                      <h1 className="lg:text-xl font-semibold tracking-wide">
                        All Specifications
                      </h1>
                      <div className="my-4 grid grid-cols-2 md:grid-cols-3 tabMe">
                        <div className="flex items-center gap-4">
                          <RiGasStationLine className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700">Fuel Type:</h2>
                            <h2>{vehicle?.features?.type?.fuel}</h2>
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <GiGearStickPattern className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> Gear Type:</h2>
                            <h2>{vehicle?.features?.type?.transmission}</h2>
                          </div>
                        </div>

                        {/* <div className="flex items-center gap-4">
                          <GiSteeringWheel className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> Drive Mode:</h2>
                            <h2>All Wheel Drive</h2>
                          </div>
                        </div> */}

                        <div className="flex items-center gap-4">
                          <FaFan className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> AC:</h2>
                            <h2>{vehicle?.features?.type?.airCondition ? 'Yes' : 'No'}</h2>
                          </div>
                        </div>

                        <div className="flex items-center gap-4">
                          <MdEventSeat className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> Seats:</h2>
                            <h2>{vehicle?.features?.type?.numberOfSeats}</h2>
                          </div>
                        </div>

                        {/* <div className="flex items-center gap-4">
                          <GiSteeringWheel className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> Drive Mode:</h2>
                            <h2>All Wheel Drive</h2>
                          </div>
                        </div> */}

                        <div className="flex items-center gap-4">
                          <IoMdCar className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> Body Type: </h2>
                            <h2>{vehicle?.type}</h2>
                          </div>
                        </div>

                        <div className="flex items-center gap-4">
                          <GiPathDistance className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700">
                              {" "}
                              Wifi:
                            </h2>
                            <h2>{vehicle?.features?.type?.hasWifi ? 'Yes' : 'No'}</h2>
                          </div>
                        </div>

                        {/* <div className="flex items-center gap-4">
                          <GiSteeringWheel className="text-5xl font-light text-sunshine text-shadow-md" />
                          <div className="text-gray-500 font-semibold text-left">
                            <h2 className="text-gray-700"> Drive Mode:</h2>
                            <h2>All Wheel Drive</h2>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <div>
                    <div className="flex gap-2 flex-wrap !m-4">
                      {vehicle?.images?.filter(Boolean)?.length > 0 &&
                        vehicle?.images?.filter(Boolean)?.map((vimage, index) => (
                          <img
                            key={vimage}
                            src={vimage}
                            alt={`variant ${index}`}
                            className="w-[300px]"
                          />
                        ))}
                    </div>
                    </div>
                  </div>
                  <div
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3"
                  >
                    <div>
                    <Link className="text-[#5250e3]" to="/terms-and-conditions">Please see our Terms & Conditions</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 bg-white rounded">
              <div className="h-48 bg-gray-200 rounded-tr rounded-tl animate-pulse"></div>

              <div className="p-5">
                <div className="h-6 rounded-sm bg-gray-200 animate-pulse mb-4"></div>

                <div className="grid grid-cols-4 gap-1">
                  <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                  <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                  <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                  <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                  <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                  <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                  <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                  <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default function TabsRender({ vehicle }: { vehicle: VehicleDetails }) {
  return <Tabs color="sunshine" vehicle={vehicle} />;
}

/**
 * TODO: 
 * datetime on booking
 * remove gaps in the about page
 * pagination on cars page
 * Filter brands/model/type/year etc - same as partners
 * check filters cars api
 */