import React from "react";
import { Order } from "../../services/orders/interfaces/order";
import { calculateTotalIvoice, formatPrice } from "../../utilities/utilities";
import Footer from "./Sections/Footer";

const InvoiceOrdersTable = ({ orders }: { orders?: Order[] }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-gray-400">
          <tr className="">
            <th scope="col" className="pl-2 py-3">
              Order Number
            </th>
            <th scope="col" className="px-0 py-3">
              Start Date
            </th>
            <th scope="col" className="px-0 py-3">
              End Date
            </th>
            <th scope="col" className="px-0 py-3">
              Car Name
            </th>
            <th scope="col" className="px-0 py-3">
              Pick Up
            </th>
            <th scope="col" className="px-0 py-3">
              Drop Off
            </th>
            <th scope="col" className="px-0 py-3">
              Total Amount
            </th>
            <th scope="col" className="px-0 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {orders && orders.length > 0 ? (
            orders?.map((order, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white cursor-pointer border-b text-xs hover:bg-gray-50 dark:hover:bg-gray-100"
                >
                  <td className="pl-2 py-4">#{order?.orderNumber}</td>
                  <td className="px-0 py-4">{`${new Date(
                    order?.startDate
                  ).getDate()}/${new Date(
                    order?.startDate
                  ).getMonth()}/${new Date(
                    order?.startDate
                  ).getFullYear()}`}</td>
                  <td className="px-0 py-4">{`${new Date(
                    order?.endDate
                  ).getDate()}/${new Date(
                    order?.endDate
                  ).getMonth()}/${new Date(order?.endDate).getFullYear()}`}</td>
                  <td className="px-0 py-4">
                    {order?.vehicle?.type + " " + order?.vehicle?.brand}
                  </td>
                  <td className="px-0 py-4">{order?.address?.pickUp}</td>
                  <td className="px-0 py-4">{order?.address?.dropOff}</td>
                  <td className="px-0 py-4">
                    {formatPrice(order?.totalAmountPaid)}
                  </td>
                  <td className="px-0 py-4">
                    <label className="px-5 py-2 bg-[#e7faec] text-[green] rounded-md">
                      {order?.status}
                    </label>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="w-full text-center">
              <td className="py-10 text-gray-400" colSpan={8}>
                No orders are associated with this invoice!
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Footer totalAmountPaid={calculateTotalIvoice(orders)} />
    </div>
  );
};

export default InvoiceOrdersTable;
