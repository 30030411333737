import moment from "moment";

export const getValue = (obj: any, path: any) =>
  path
    .replace(/\[([^[\]]*)]/g, ".$1.")
    .split(".")
    .filter((prop: any) => prop !== "")
    .reduce(
      (prev: any, next: any) =>
        prev instanceof Object ? prev[next] : undefined,
      obj
    );

export const NotBeforeToday = function (date: any) {
  const now = new Date(); //this gets the current date and time
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() >= now.getDate() &&
    date.getDay() > 0 &&
    date.getDay() < 6
  )
    return [true, ""];
  if (
    date.getFullYear() >= now.getFullYear() &&
    date.getMonth() > now.getMonth() &&
    date.getDay() > 0 &&
    date.getDay() < 6
  )
    return [true, ""];
  if (
    date.getFullYear() > now.getFullYear() &&
    date.getDay() > 0 &&
    date.getDay() < 6
  )
    return [true, ""];
  return [false, ""];
};

export const formatDate = (value: string) => {
  // format date
  const isFalsePositive = !Number.isNaN(+value);

  if (!isFalsePositive && moment(value).isValid()) {
    let d = new Date(value);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();
    let hours = "" + d.getHours();
    let minutes = "" + d.getMinutes();
    let seconds = "" + d.getSeconds();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;

    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;
    return [day, month, year].join("-");
  } else {
    return value;
  }
};

export const formatTime = (value: string) => {
  // format date
  const isFalsePositive = !Number.isNaN(+value);

  if (!isFalsePositive && moment(value).isValid()) {
    let d = new Date(value);
    let hours = d.getHours();
    let minutes = "" + d.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be converted to 12

    let formattedHours = ("0" + hours).slice(-2);
    let formattedMinutes = ("0" + minutes).slice(-2);
    
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  } else {
    return value;
  }
};

export const formatDateTime = (value: string) => {
  // format date
  const isFalsePositive = !Number.isNaN(+value);

  if (!isFalsePositive && moment(value).isValid()) {
    let d = new Date(value);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();
    let hours = "" + d.getHours();
    let minutes = "" + d.getMinutes();
    let seconds = "" + d.getSeconds();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;

    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;
    return [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")    
  } else {
    return value;
  }
};

export const getDatesDifference = (dateStart: string, dateEnd: string) => {
  const _days = moment(dateEnd).diff(moment(dateStart), "days");
  return _days + 1;
};

export const calculateTotalCartPrice = (vehicles: any) => {
  return vehicles.reduce((total: any, item: any): any => {
    let days = +item?.details?.response?.numberOfDaysOrderedFor;
    let vehicleCharge = days * +item?.vehicle?.price;
    return vehicleCharge + total;
  }, 0);
};

export const calculateTotalIvoice = (invoiceOrders: any) => {
  return invoiceOrders.reduce((total: any, item: any): any => {
    return item?.totalAmountPaid + total;
  }, 0);
};

// format price
export const formatPrice = (price: number) => {
  let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GHS",
  });
  return dollarUS.format(price);
};

// return matched data
export const getMatchedResults = (parent: any, child: any) => {
  return parent?.filter((elem: any) => {
    return child.some((ele: any) => {
      return ele === elem?._id;
    });
  });
};


export function getUniqueValues(data: any, key?: string) {
  if (Array.isArray(data)) {
    if (key) {
      // If a key is provided, it's an array of objects
      const uniqueKeys = new Set();
      return data.reduce((result, item) => {
        if (!uniqueKeys.has(item[key])) {
          uniqueKeys.add(item[key]);
          result.push(item[key]);
        }
        return result;
      }, []);
    } else {
      // If no key is provided, it's a simple array
      return Array.from(new Set(data));
    }
  } else {
    throw new Error('Input is not an array or an array of objects.');
  }
}

export const formatPascalCase = (inputString: string): string => {
  const words = inputString
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between lowercase and uppercase letters
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return words;
};

export const createPageList = (
  totalPages: number,
  currentPage: number,
  maxVisiblePages: number
): number[] => {
  const visiblePages: number[] =[1];
  const upperBound = Math.min(totalPages, currentPage + Math.floor(maxVisiblePages / 2));
  const lowerBound = Math.max(2, upperBound - maxVisiblePages + 2);

  for (let i = lowerBound; i <= upperBound; i++) {
      visiblePages.push(i);
  }

  if(totalPages > upperBound){
      visiblePages.push(totalPages);
  }

  return visiblePages;
}

export const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export const slugify = (text: string): string =>
  text
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-")
    .trim();
