// VehicleList.tsx
import React from "react";
import CarDeals from "../../components/CarDeals/CarDeals";
import { Vehicle } from "../../types/carList";
import NotFound from "../../components/NotFound/NotFound";

interface VehicleListProps {
  vehicles: Vehicle[] | undefined;
}

const VehicleList: React.FC<VehicleListProps> = ({ vehicles }) => {

  return (
    <div className={`my-6 flex flex-col justify-between gap-10 flex-wrap h-fit max-w-7xl lg:mx-auto md:mx-4 relative ${vehicles && vehicles?.length > 0 ? "md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3" : ""}`}>

        {vehicles && vehicles?.length > 0 ? vehicles?.map((vehicle: Vehicle, index) => {
          return <CarDeals vehicle={vehicle} key={index} index={index} />;
        }) : <NotFound message="No Vehicles Found!" />}
    </div>
  );
};

export default VehicleList;
