import React from "react";

const Map = () => {
  return (
    <div className="w-full h-full">
      <iframe
        title="GoogleMap"
        className="contact-map w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1985.5212642895121!2d-0.1993528!3d5.5607153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf909d0bc5e4bf%3A0x878222581c1fc765!2sVALCO%20Trust%20House%2C%20Castle%20Road%2C%20Accra!5e0!3m2!1sen!2sgh!4v1631544882074!5m2!1sen!2sgh"
      />
    </div>
  );
};

export default Map;
