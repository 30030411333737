import React, { useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import { useAppSelector } from "../../store/hooks";
// import { fetchVehicleByFilters } from "../../store/vehicles/vehiclesSlice";
import Footer from "../../components/Footer/Footer";
import Loader from "../Generics/pulsingLoader/Loader";
import CarDeals from "../../components/CarDeals/CarDeals";
import { Vehicle } from "../../types/carList";
import VehicleSearch from "./Filter/VehicleSearch";
import Pagination from "../../components/Pagination/Pagination";
import NotFound from "../../components/NotFound/NotFound";
import Divider from "../Generics/Divider";

const CarListing = () => {
  const { noInternet } = useAppSelector((state) => state.vehicles);
  // const dispatch = useAppDispatch();


  // local state Loader
  // const [searchTerm, setSearchTerm] = useState("");
  // const [error, setError] = useState("");

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nPages, setNPages] = useState<number>(0);
  const [totalDocs, setTotalDocs] = useState<number>(0);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>();

  // const handleInputChange = (e: any) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleSearch = () => {
  //   if (searchTerm.trim() === "") {
  //     setError("Please enter a value in the field");
  //   } else {
  //     const query = `type=${searchTerm}`;
  //     dispatch(fetchVehicleByFilters({ query, page: 1, limit: 10 }));
  //   }
  // };

  const showVehicles =
    vehicles && vehicles?.length > 0 ? (
      vehicles?.map((vehicle, index) => {
        return <CarDeals vehicle={vehicle} key={index} index={index} />;
      })
    ) : (
      <div className="w-full text-center absolute top-[25%] left-0 right-0 text-2xl font-semibold text-sunshine">
        {noInternet ? (
          <NotFound message="No internet connection, try again!" />
        ) : (
          <NotFound message="No Vehicles Found!" />
        )}
      </div>
    );

  return (
    <div>
      <Banner title="Vehicle For Rent" />
      {/* Main Page */}
      <div className="relative w-full bg-white">
        <div className="absolute breadcrumbs text-sm antialiased w-fit z-10 -top-6 left-[50%] translate-x-[-50%] px-4 py-3 rounded-lg shadow-xl shadow-sunshine/40 bg-sunshine !text-white">
          <ul>
            <li>
              <Link to="/" className="text-white">
                Home
              </Link>
            </li>

            <li>
              <Link to={`/car-listing`}>Cars</Link>
            </li>
          </ul>
        </div>

        {/* cars */}
        <div className="grid grid-cols-1 gap-8 px-5 mx-auto max-w-7xl py-28 md:grid-cols-3">
          {/* filter */}
          <div>
            <div className="col-span-1 px-5 py-4 border border-gray-300 border-dashed rounded-2xl h-fit ">
              <h1 className="mb-5 text-3xl font-bold">Filter cars by: </h1>
              <Divider />
              {/* {error && <Message message={error} />} */}
              {/* input */}
              {/* <div className="z-50 flex items-center justify-between w-full px-3 py-3 my-8 bg-white shadow-xl rounded-xl">
                <input
                  type="text"
                  placeholder="Search by Car type"
                  className="h-full flex-[0.9] py-3 px-2 text-gray-700 inputMe text-lg !border-2"
                  value={searchTerm}
                  onChange={(e: any) => handleInputChange(e)}
                />
                <button
                  type="submit"
                  onClick={handleSearch}
                  className="bg-sunshine flex-[0.1] text-white font-semibold text-shadow-sm px-5 py-4 shadow-lg rounded-xl cursor-pointer transition duration-300 ease-in-out hover:-translate-x-2"
                >
                  <BsSearch />
                </button>
              </div> */}
              <VehicleSearch
                // searchTerm={searchTerm}
                page={currentPage}
                setVehicles={setVehicles}
                setTotalDocs={setTotalDocs}
                setNPages={setNPages}
                setIsLoadingVehicles={setIsLoadingVehicles}
              />
            </div>
          </div>
          <div className="relative col-span-2">
            <div className="rounded-lg bg-[#f7f7f7] py-3 px-6 flex justify-between">
              <h1 className="font-medium text-gray-400">
                Showing {vehicles?.length} Of {totalDocs} Result
              </h1>
            </div>
            <div className="carListing overflow-y-scroll my-4 grid md:grid-cols-2 grid-cols-1 justify-between gap-4 md:gap-10 flex-wrap h-[40rem]">
              {isLoadingVehicles ? (
                <div className="absolute top-[50%] right-[50%]">
                  <Loader />
                </div>
              ) : (
                showVehicles
              )}
            </div>
            {nPages > 1 && (
              <Pagination
                currentPage={currentPage}
                nPages={nPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CarListing;
