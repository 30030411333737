import React, { useState, useEffect, useCallback, useRef } from "react";
import axios, { CancelTokenSource } from "axios";
import { VehiclesService } from "../../../services/vehicles/VehiclesService";
import ApiConstants from "../../../configuration/constants";
import SearchableMultiSelect from "./SearchableMultiSelect";
import Divider from "../../Generics/Divider";
import { capitalize } from "@mui/material";
import { Vehicle } from "../../../types/carList";

interface VehicleFilter {
  type?: string;
  year?: number;
  model?: string;
  transmission?: string;
  fuel?: string;
  brand?: string;
  status?: string;
}

interface Brand {
  id: number;
  name: string;
}

interface Model {
  id: number;
  make_id: number;
  name: string;
}

interface VSProps {
  setVehicles: (data: Vehicle[]) => void;
  setNPages: (data: number) => void;
  setTotalDocs: (data: number) => void;
  setIsLoadingVehicles: (data: boolean) => void;
  // searchTerm: string;
  page: number;
}

const VehicleSearch: React.FC<VSProps> = ({
  setVehicles,
  setNPages,
  setTotalDocs,
  page,
  // searchTerm,
  setIsLoadingVehicles,
}) => {
  const [filters, setFilters] = useState<VehicleFilter>({});
  const [carBrands, setCarBrands] = useState<Brand[]>([]);
  const [carTypes, setCarTypes] = useState<string[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [statuses, ] = useState<string[]>(["Available", "Booked"]);
  const [transmissions, ] = useState<string[]>(["Automatic","Manual",]);
  const [fuelTypes, ] = useState<string[]>(["Petrol", "Diesel","Electric","Hybrid"]);

  const [loadingBrands, setLoadingBrands] = useState<boolean>(false);
  const [loadingModels, setLoadingModels] = useState<boolean>(false);
  const [isModelError, setIsModelError] = useState<boolean>(true);
  const [isResetInputs, setIsResetInputs] = useState(false);

  let cancelTokenSource: CancelTokenSource | null = null;

  const getModels = async (brand?: string, year?: number) => {
    if (brand && year) {
      try {
        setLoadingModels(true);
        const modelsData = await VehiclesService.getModelByBrand({
          brand,
          year,
        });
        setModels(modelsData);
      } catch (error) {
        console.error("Error fetching models:", error);
      } finally {
        setIsModelError(false);
        setLoadingModels(false);
      }
    } else {
      setIsModelError(true);
    }
  };

  const debouncedFilterChange = useCallback((newFilters: VehicleFilter) => {
    setIsResetInputs(false)
    setFilters(newFilters);
  }, []);

  const handleTransmissionChange = (option: string) => {
    debouncedFilterChange({ ...filters, transmission: option });
  };

  const handleFuelTypeChange = (option: string) => {
    debouncedFilterChange({ ...filters, fuel: option });
  };

  const handleTypeChange = (option: string) => {
    debouncedFilterChange({ ...filters, type: option });
  };

  const handleYearChange = (option: number) => {
    debouncedFilterChange({ ...filters, year: option });
    getModels(filters.brand, option);
  };

  const handleModelChange = (option: string) => {
    debouncedFilterChange({ ...filters, model: option });
  };

  const handleBrandChange = async (option: string) => {
    debouncedFilterChange({ ...filters, brand: option });
    getModels(option, filters.year);
  };

  const handleStatusChange = (status: string) => {
    debouncedFilterChange({ ...filters, status });
  };

  const handleClearFilters = () => {
    setFilters({});
    setIsResetInputs(true);
    resetCheckboxes(); 
  };
  
  const resetCheckboxes = () => {
    const checkboxInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="radio"]');
    checkboxInputs.forEach((input: HTMLInputElement) => {
      input.checked = false;
    });
  };

  // Fetch car types, years, models initially
  useEffect(() => {
    let isMounted = true;
    (async function () {
      setLoadingBrands(true);
      if (isMounted) {
        const brandsData = await VehiclesService.getVehicleBrands();
        setCarBrands(brandsData);
        setLoadingBrands(false);
        const typesData = await VehiclesService.getType();
        setCarTypes(typesData);
        const yearsData = await VehiclesService.getYears();
        setYears(yearsData);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    // Make API call when filters change
    const fetchData = async () => {
      try {
        setIsLoadingVehicles(true);
        // Construct query string
        let query = `${
          ApiConstants.baseUrlCarsAPI
        }/vehicles?page=${page}&limit=${6}&`;
        Object.entries(filters).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => {
              query += `${key}=${encodeURIComponent(v)}&`;
            });
          } else {
            query += `${key}=${encodeURIComponent(value)}&`;
          }
        });
        // Remove trailing '&'
        query = query.slice(0, -1);

        // Make API call with constructed query
        const response = await axios.get(query);
        // Process response as needed
        setVehicles(response?.data?.data?.docs);
        setNPages(response?.data?.data?.totalPages);
        setTotalDocs(response?.data?.data?.totalDocs);
        setIsLoadingVehicles(false);
      } catch (error) {
        setIsLoadingVehicles(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      // Cancel any pending requests when component unmounts
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted");
      }
    };
  }, [cancelTokenSource, filters, page]);
  
  useEffect(() => {
    if (isResetInputs) {
      setIsResetInputs(false); // Reset isResetInputs to false after inputs are cleared
    }
  }, [isResetInputs]);

  return (
    <div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-col flex-1 gap-4">
          <SearchableMultiSelect
            label="Type"
            required
            onSelect={handleTypeChange}
            initialOptions={carTypes}
            loading={loadingBrands}
            isResetInputs={isResetInputs}
            name="type"
            placeholder="Search type..."
          />
          <SearchableMultiSelect
            label="Brand"
            required
            onSelect={handleBrandChange}
            initialOptions={carBrands}
            loading={loadingBrands}
            isResetInputs={isResetInputs}
            name="brand"
            placeholder="Search brands..."
          />
        </div>
        <div className="flex flex-col flex-1 gap-4">
          <SearchableMultiSelect
            label="Year"
            required
            onSelect={handleYearChange}
            initialOptions={years}
            loading={loadingBrands}
            isResetInputs={isResetInputs}
            name="years"
            placeholder="Select Year..."
          />
          <div className="flex flex-col" >
            <SearchableMultiSelect
              label="Model"
              required
              onSelect={handleModelChange}
              initialOptions={models}
              loading={loadingBrands}
              isResetInputs={isResetInputs}
              name="models"
              placeholder="Select Model..."
            />
            {/* {isModelError && (
              <span className="my-2 text-sm text-red-700">
                Please select brand and year first!
              </span>
            )} */}
          </div>
        </div>
      </div>
      <div className="">
        <h1 className="font-bold">Other Filters</h1>
        <Divider />
      </div>
      <div className="flex justify-start gap-8">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            <span className="font-bold">Fuel Type</span>
            {fuelTypes.map((fuelType) => (
              <label key={fuelType} className="flex gap-2 align-middle">
                <input
                  type="radio"
                  name="fuelType"
                  value={fuelType}
                  onChange={() => handleFuelTypeChange(fuelType)}
                />
                {capitalize(fuelType)}
              </label>
            ))}
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col">
              <span className="font-bold">Status</span>
              {statuses.map((status) => (
                <label key={status} className="flex gap-2 align-middle">
                  <input
                    type="radio"
                    name="status"
                    value={status}
                    onChange={() => handleStatusChange(status)}
                  />
                  {capitalize(status)}
                </label>
              ))}
            </div>
            <button
              className="w-full px-5 py-3 mt-2 mb-4 font-semibold tracking-tight text-center text-white transition duration-500 ease-in-out rounded-lg shadow-xl hover:translate-y-1 bg-sunshine shadow-sunshine/40"
              onClick={handleClearFilters}
            >
              Clear Filters
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            <span className="font-bold">Transmission</span>
            {transmissions.map((transmission) => (
              <label key={transmission} className="flex gap-2 align-middle">
                <input
                  type="radio"
                  name="transmission"
                  value={transmission}
                  onChange={() => handleTransmissionChange(transmission)}
                />
                {capitalize(transmission)}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleSearch;
