import React, { useEffect, useState } from "react";

type Props = {
  message: string;
  textColor?: string;
  bgColor?: string;
};

const Message = ({ message, textColor, bgColor }: Props) => {
  const [theMessage, setTheMessage] = useState(message);

  useEffect(() => {
    const timeoutId = setTimeout(() => setTheMessage(""), 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {theMessage ? (
        <p
          className={`${textColor ? textColor : "text-sunshine"} ${
            bgColor ? bgColor : "bg-sunshine/10"
          } py-1 px-2 shadow w-fit mx-auto`}
        >
          {theMessage}
        </p>
      ) : null}
    </>
  );
};

export default Message;
