import React, { useState } from "react";
import { GiGearStickPattern } from "react-icons/gi";
import { MdOutlineFavorite, MdAirlineSeatReclineNormal } from "react-icons/md";
import { RiGasStationLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { formatDateTime, formatPrice } from "../../utilities/utilities";
import { Vehicle } from "../../types/carList";

const CarDeals = ({
  vehicle,
  index,
}: {
  vehicle: Vehicle;
  index: number;
}) => {
  const navigate = useNavigate();
  // const [love, setLove] = useState(false);


  const handleDetails = (vehicle: Vehicle) => {
    navigate(`/car-details/${vehicle._id}`);
  };


  return (
    <div
      key={index}
      className="shadow-lg rounded-lg py-5 px-5 relative text-left bg-white"
    >
      {/* <MdOutlineFavorite
        onClick={() => setLove(!love)}
        className={`absolute right-10 top-10 text-3xl ${
          love ? "text-sunshine" : "text-gray-300"
        } font-bold shadow-2xl text-shadow-xl cursor-pointer`}
      /> */}
      <div className="w-full bg-[#f2f2f2] rounded-lg">
        <img
          src={`${
            vehicle?.images[0]
              ? vehicle?.images[0]
              : "/assets/images/default-car.webp"
          }`}
          alt={vehicle?.brand}
          className="w-full h-56 rounded-lg object-cover"
        />
      </div>
      <div className="flex justify-between items-center ">
        <div className="w-[50%]" title={`${vehicle?.brand} ${vehicle?.model}`}>
          <h1 className="font-bold text-2xl tracking-wide mt-4 text-shadow-sm truncate">
            {vehicle?.brand} {vehicle?.model}
          </h1>
          <h1 className="italic text-gray-500 text-sm">
            Owner: {vehicle?.partner?.business?.name}
          </h1>
        </div>
        <div className="text-sunshine text-sm font-semibold capitalize">
          {vehicle?.status.toLowerCase() === "booked" ? `Booked Until ${formatDateTime(vehicle?.bookedUntil)}` : vehicle?.status }
          <h1 className="italic text-gray-500 font-normal text-sm">
            Location: {vehicle?.location ? vehicle?.location : "Accra"}
          </h1>
        </div>
      </div>
      <div className="text-sunshine font-semibold flex justify-between items-center my-5 gap-4">
        <div className="flex items-baseline">
          <div className="flex flex-col justify-start">
            <span className="!text-gray-500 text-sm">Vehicle Charge/Day</span>
            <h1 className="text-xl">{formatPrice(+vehicle?.price)}</h1>
          </div>
        </div>
        <div className="flex items-baseline !text-gray-500">
          <div className="flex flex-col justify-start">
            <span className="!text-gray-500 text-sm">Driver's Charge/Day</span>
            <h1 className="text-xl">{formatPrice(+vehicle?.driverCost)}</h1>
          </div>
        </div>
      </div>
      <hr />
      {/* features */}
      <div className="my-5 text-gray-500 grid grid-cols-3 gap-1 text-shadow-sm">
        {/* fuel */}
        <div className="flex items-center gap-1 ">
          <RiGasStationLine className="text-2xl" />
          <h2>{vehicle?.features?.type?.fuel}</h2>
        </div>
        {/* transimission */}
        <div className="flex items-center gap-1">
          <GiGearStickPattern className="text-2xl" />
          <h2>{vehicle?.features?.type?.transmission}</h2>
        </div>
        {/* distance */}
        <div className="flex items-center gap-1">
          <MdAirlineSeatReclineNormal className="text-2xl" />
          <h2 className="truncate">{vehicle?.features?.type?.numberOfSeats}</h2>
        </div>
      </div>
      <div className="my-5 text-gray-500 flex justify-between text-shadow-sm">
        {/* fuel */}
        <div className="">
          <h2>Fuel cost excluded</h2>
        </div>
        {/* distance */}
        <div className="">
          <h2 className="truncate">Toll fees excluded</h2>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={() => handleDetails(vehicle)}
          className="font-semibold tracking-tight hover:translate-y-1 transition duration-500 ease-in-out text-center w-full py-3 mt-2 mb-4 shadow-xl rounded-lg bg-sunshine text-white shadow-sunshine/40"
        >
          Rent Now
        </button>
      </div>
    </div>
  );
};

export default CarDeals;
