import React, { useEffect, useRef, useState, Fragment } from "react";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { appSingleton } from "../../configuration/appUtils";
import ApiConstants from "../../configuration/constants";
import { ApiUtils } from "../../services/http/api/rest";
import { useAppSelector } from "../../store/hooks";
import Error from "../Error/Error";
import Message from "../Message/Message";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

let currentOPTIndex: number = 0;

const ForgotPassword = () => {
  const { activationToken, isAuthenticated } = useAppSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const next = searchParams.get("next") || "/";
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));

  //   dynamically move cursor to the next input
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);

  const handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    // get a copy of otp
    const newOTP: string[] = [...otp];
    // update otp at index with the last value from the input
    newOTP[currentOPTIndex] = value.substring(value.length - 1);

    // set focus to next or previous
    if (!value) {
      setActiveOTPIndex(currentOPTIndex - 1);
    } else {
      setActiveOTPIndex(currentOPTIndex + 1);
    }

    // update otp
    setOtp(newOTP);
  };

  //   handle backspace
  const handleBackspace = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // update currentOPTIndex
    currentOPTIndex = index;
    if (key === "Backspace") {
      setActiveOTPIndex(currentOPTIndex - 1);
    }
  };

  const handlePasswordChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    setNewPassword(value);
  };

  const handleChangePassword = () => {
    const token = otp.join("");

    if (token === "" || (activationToken && +token !== +activationToken)) {
      setError("Please Make Sure Your Activation Code is Correct!");
      return;
    }

    if (newPassword === "") {
      setError("Please Enter Your New Password!");
      return;
    }

    const credentials = {
      token: token,
      password: newPassword,
    };

    setIsLoading(true)
    new ApiUtils()
      .callApi(appSingleton.connection, {
        url: ApiConstants.apiResetPasswordUrl,
        data: {
          token: credentials.token,
          password: credentials.password,
        },
      })
      .then((res) => {
        setIsLoading(false)
        if (res?.rawBody?.message) {
          setsuccessMessage(res?.rawBody?.message);
          setTimeout(() => {
            setsuccessMessage("");
            navigate("/login");
          }, 6000);
        } else {
          setError("Token is invalid or has expired");
          setTimeout(() => {
            setError("");
            navigate("/login");
          }, 6000);
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setError(error);
        setTimeout(() => {
          setError("");
          navigate("/login");
        }, 6000);
      });
    setNewPassword("");
    // update otp
    setOtp(new Array(6).fill(""))
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <>
      {isAuthenticated && <Navigate to={next} replace={true} />}
      <div className="absolute top-[25%] bottom-[25%] right-[30%] left-[30%] border border-[#f3f3f3] w-[40%] flex flex-col justify-center items-center align-middle py-20 my-8">
        {successMessage && <Message message={successMessage} />}
        {/* Register Title */}
        <h1 className="text-md font-semibold tracking-wide antialiased">
          Please Enter Your Code and New Password to Continue!
        </h1>
        <div className="flex flex-col justify-center items-center space-x-2 mt-4 mb-2">
          <div className="flex justify-center items-center space-x-2 mt-4">
            {otp?.map((_, index) => {
              return (
                <Fragment key={index}>
                  <input
                    type="number"
                    min={0}
                    ref={index === activeOTPIndex ? inputRef : null}
                    value={otp[index]}
                    onChange={handleChange}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    className="w-12 h-12 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition spin-button-none"
                  />
                  {index === otp.length - 1 ? null : (
                    <span className="w-2 py-0.5 bg-gray-400" />
                  )}
                </Fragment>
              );
            })}
          </div>
          <div className="mt-4 relative">
            <form autoComplete="off">
              <input
              type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handlePasswordChange}
                required
                autoComplete="off"
                placeholder="Enter New Password"
                className="w-full border-2 rounded-none bg-transparent outline-none text-left px-2 font-semibold text-xl border-[#f3f3f3] focus:border-gray-700 focus:text-gray-700 text-gray-400 py-1"
              />
              <span
                className="absolute top-3 right-3 cursor-pointer z-10"
                onClick={handleTogglePassword} >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={20} />
                ) : (
                  <AiOutlineEye size={20} />
                )}
              </span>
            </form>
          </div>
        </div>
        {error && <Error message={error} />}
        <button
          onClick={handleChangePassword}
          className="relative w-[40%] mx-auto text-center py-3 px-4 mt-4 font-medium hover:shadow-lg text-white bg-sunshine focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sunshine"
        >
          {" "}
          {isLoading ? (
            <ClipLoader color="white" size={20} />
          ) : (
            <div className="text-center m-auto">Change Password</div>
          )}
        </button>
      </div>
    </>
  );
};

export default ForgotPassword;
