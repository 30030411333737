import React from "react";

const Banner = ({ title }: { title: string }) => {

  return (
    <div className="font-[satisfy] font-bold text-white text-shadow-xl text-4xl md:text-7xl text-center py-12 md:py-32 border px-4 md:px-0 bg-about bg-cover bg-no-repeat bg-center">
      {title}
    </div>
  );
};

export default Banner;
