import React, { Fragment, useEffect, useRef, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  completeAccountActivationAndlogin,
  resendActivationActivationCode,
} from "../../store/user/actions";
import Error from "../Error/Error";
import Message from "../Message/Message";

let currentOPTIndex: number = 0;
const AccountsActivation = () => {
  const { activationToken, isAuthenticated, newRegisteredEmail, status } =
  useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const next = searchParams.get("next") || "/";
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));

  //   dynamically move cursor to the next input
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    // get a copy of otp
    const newOTP: string[] = [...otp];
    // update otp at index with the last value from the input
    newOTP[currentOPTIndex] = value.substring(value.length - 1);

    // set focus to next or previous
    if (!value) {
      setActiveOTPIndex(currentOPTIndex - 1);
    } else {
      setActiveOTPIndex(currentOPTIndex + 1);
    }

    // update otp
    setOtp(newOTP);
  };

  //   handle backspace
  const handleBackspace = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // update currentOPTIndex
    currentOPTIndex = index;
    if (key === "Backspace") {
      setActiveOTPIndex(currentOPTIndex - 1);
    }
  };

  const handleCompleteActivation = () => {
    const token = otp.join("");
    if (token === "") {
      setError("Please Enter Your Activation Code!");
      return null;
    }
    if (token !== activationToken) {
      setError("Please Enter a valid Activation Code!");
      return null;
    }
    // once we're on this comoponent, we;re sure we have activationToken, that's why we force is like so : activationToken!
    dispatch(completeAccountActivationAndlogin(token));
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <>
      <div className="absolute top-4 right-4">
        <Message message="Your Activation Code Has Been Sent To Your Email!" />
      </div>

      {isAuthenticated && <Navigate to={next} replace={true} />}
      {(!isAuthenticated && activationToken) && <div className="absolute px-2 top-[25%] bottom-[25%] right-[30%] left-[30%] border border-[#f3f3f3] w-[40%] flex flex-col justify-center items-center align-middle py-20 my-8">
        {/* Register Title */}
        <h1 className="text-md text-center font-normal tracking-wide antialiased">
          Please Enter the Code Sent to Your Email{" "}
          {newRegisteredEmail && (
            <span className="font-semibold"> : {newRegisteredEmail} </span>
          )}
          to Activate Your Account and Continue!
        </h1>
        <div className="flex flex-wrap justify-center items-center align-middle gap-y-4 mt-4">
          {otp?.map((_, index) => {
            return (
              <Fragment key={index}>
                <input
                  type="number"
                  min={0}
                  ref={index === activeOTPIndex ? inputRef : null}
                  value={otp[index]}
                  onChange={handleChange}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  className="w-12 h-12 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition spin-button-none"
                />
                {index === otp.length - 1 ? null : (
                  <span className="w-2 py-0.5 mx-2 bg-gray-400" />
                )}
              </Fragment>
            );
          })}
        </div>
        {/* ERROR */}
        {error && <Error message={error} />}
        <button
          onClick={handleCompleteActivation}
          className="relative w-[40%] mx-auto text-center py-3 px-4 mt-4 font-medium hover:shadow-lg text-white bg-sunshine focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sunshine"
        >
          Activate Account
        </button>

        <div
          className="my-5 cursor-pointer text-blue-200"
          onClick={() =>
            dispatch(resendActivationActivationCode(newRegisteredEmail!))
          }
        >
          {status === "loading" ? "Sending..." : " Resend Code"}
        </div>
      </div>}
    </>
  );
};

export default AccountsActivation;
