import React from "react";
import { GiFlowerEmblem } from "react-icons/gi";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto py-24">
        <article>
          <h1 className="text-5xl text-center font-bold tracking-tight text-shadow-sm">
          Imperial Shuttle Ltd - Privacy Policy
          </h1>
          <div className="flex items-center justify-center gap-1 text-sunshine my-4">
            <div className="justify-end">
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-10 flex justify-end">
                <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
              </div>
            </div>
            <GiFlowerEmblem className="text-2xl" />
            <div>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
          </div>
          <div className="text-center">
            <p className="text-md text-gray-500">Last Updated: 23rd January 2024</p>
          </div>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">1. Introduction</h2>
            <p>
            Imperial Shuttle is dedicated to safeguarding the privacy of our users. This policy outlines how we collect, use, and protect your personal information when you use our platform to connect with drivers for car services including rentals. 
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">2. Information We Collect</h2>
            <ul className="list-disc"> We may collect and process the following data:
              <span className="ml-6 block">
                <li>Customer contact information (name, address, email, phone number)</li>
                <li>Trip details, including pick-up and drop-off locations</li>
                <li>Driver information (name, photo, vehicle details)</li>
                <li>Payment details for transactions</li>
              </span>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">3. How We Use Your Information</h2>
            <ul className="list-disc"> We use your data for the following purposes:
              <span className="ml-6 block">
                <li>Facilitating connections between customers and drivers for rental services</li>
                <li>Verifying the identity of both customers and drivers</li>
                <li>Processing and managing rental reservations</li>
                <li>Providing customer support and communication</li>
              </span>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">4. Data Security</h2>
            <p>
            We implement robust security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">5. Sharing Information with Third Parties</h2>
            <p>
            We may share your information with drivers and third-party service providers for the sole purpose of facilitating the rental services you request.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">6. Location Tracking</h2>
            <p>
            We may collect and use location data for purposes like matching customers with nearby drivers and optimizing the rental experience.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">7. Cookies</h2>
            <p>
            Our website may use cookies to enhance your browsing experience. You can manage cookie preferences through your browser settings.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">8. Your Rights</h2>
            <ul className="list-disc"> You have the right to:
              <span className="ml-6 block">
                <li>
                Access, correct, or delete your personal information
                </li>
                <li>
                Opt-out of marketing communications
                </li>
              </span>
            </ul>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">9. Retention Period</h2>
            <p>
            We retain your data for as long as necessary to fulfill the purposes outlined in this policy or as required by law.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">10. Changes to this Policy</h2>
            <p>
            We reserve the right to update this policy. Please check our website periodically for any changes.
            </p>
          </section>
          <section className="shadow-lg rounded-lg py-5 px-5 my-3 text-left bg-white">
            <h2 className="font-bold">11. Contact Us</h2>
            <p>
            If you have questions about this privacy policy or wish to exercise your rights, contact us at <a className="font-bold text-[#fe3227]" href="mailto:support@imperialshuttle-gh.com">support@imperialshuttle-gh.com</a>.
            </p>
          </section>
        </article>
      </div>
        <Footer />
    </>
  );
};

export default PrivacyPolicy;
