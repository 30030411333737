import { useState, useEffect, useRef } from "react";
import { FaLock } from "react-icons/fa";
import { Link, Navigate, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import Register from "../components/Register/Register";
import Footer from "../components/Footer/Footer";
import Error from "./Error/Error";
import { forgotPassword, login } from "../store/user/actions";
import { resetUserState } from "../store/user/userSlice";

export default function Example() {
  const navigate = useNavigate();
  const location = useLocation();
  const targetSection = location.state?.target
  const targetRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const { isAuthenticated, loginStatus, loginError } = useAppSelector(
    (state) => state.user
    );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const next = searchParams.get("next") || "/";


  useEffect(() => {
    dispatch(resetUserState());
  }, [dispatch]);

  useEffect(() => {
    // You can scroll to the section on component mount
 if(targetSection === "register") {
   targetRef?.current.scrollIntoView({ behavior: 'smooth' });
 }
  }, [targetSection]);

  const handleForgotPassword = () => {
    if (email === "") {
      setError("Please enter your email!");
      return null;
    }

    dispatch(forgotPassword(email));
    navigate("/forgotpassword");
  };

  return (
    <>
      {isAuthenticated && <Navigate to={next} replace={true} />}
      {/* Container */}
      <div className="max-w-7xl mx-auto h-fit px-4 py-12 bg-white text-black relative">
        {/* Heading */}
        <div className="my-12">
          <h1 className="text-xl font-medium tracking-wide ">Register</h1>
          {/* BreadCrumbs */}
          <div className="breadcrumbs text-sm antialiased">
            <ul>
              <li>
                <Link to="/" className="text-gray-500">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/login">Register</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Login section */}
        <div>
          {/* Login Heading */}
          <div className="my-8">
            <h1 className="text-md font-semibold tracking-wide antialiased">
              Login Your Account
            </h1>
            <p className="text-sm text-gray-500">
              Login to your accunt to discover all great features in this
              application.
            </p>
          </div>

          <div className="my-8 flex flex-col space-y-4 md:space-y-0 md:flex-row gap-10 relative">
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="lg:w-4/5 appearance-none shadow-sm relative block w-full px-3 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#112046] focus:border-[#112046] focus:z-10 sm:text-sm"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="w-full lg:w-4/5 border flex flex-col justify-start space-y-1 relative">
              <input
              id="current-password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              required
              className="appearance-none shadow-sm relative block w-full px-3 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#112046] focus:border-[#112046] focus:z-10 sm:text-sm"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span
              className="absolute top-3 right-5 cursor-pointer z-10"
              onClick={handleTogglePassword} >
              {showPassword ? (
                <AiOutlineEyeInvisible size={20} />
              ) : (
                <AiOutlineEye size={20} />
              )}
            </span>
            </div>
            

            <button
              onClick={() =>
                dispatch(login({ email: email, password: password }))
              }
              className="md:relative md:w-2/5 md:mx-auto text-center py-3.5 px-4 font-medium hover:shadow-lg text-white bg-sunshine focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sunshine"
            >
              {!loginError && loginStatus === "loading" ? (
                <ClipLoader color="white" size={20} />
              ) : (
                <div className="text-center m-auto">
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3 ">
                    <FaLock
                      className="hidden xl:block h-5 w-5 text-[white] group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  Log in
                </div>
              )}
            </button>
          </div>

          {error && (
            <div className="relative">
              <div className="absolute -top-8">
                <Error message={error} />
              </div>
            </div>
          )}
          <div className="flex gap-6 items-center w-fit">
            <div className="text-sm">
              <p
                onClick={handleForgotPassword}
                className="font-medium text-corn cursor-pointer"
              >
                Forgot your password?
              </p>
            </div>
          </div>
          <hr ref={targetRef} className="my-12" />

          {/* Don't have an account */}
          <Register />
        </div>
      </div>
      <Footer />
    </>
  );
}
