import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { GiFlowerEmblem } from "react-icons/gi";

const About = () => {
  return (
    <div>
      <div>
        {/* About Title */}
        <Banner title="About Us" />
        {/* About Header */}
        <div className=" text-center flex flex-col md:flex-row items-center max-w-7xl mx-2 md:mx-auto px-5 relative">
          <div className="absolute breadcrumbs text-sm antialiased w-fit z-10 -top-6 left-[50%] translate-x-[-50%] px-4 py-3 rounded-lg shadow-xl shadow-sunshine/40 bg-sunshine !text-white">
            <ul>
              <li>
                <Link to="/" className="text-white">
                  Home
                </Link>
              </li>

              <li>
                <Link to={`/about`}>About</Link>
              </li>
            </ul>
          </div>
          {/* welcome */}
          {/* Left Side */}
          <div className="w-full">
            {/* <h2 className="text-2xl font-bold text-[#001238] my-5 md:my-2 ">
              Welcome To Imperial Shuttle
            </h2> */}
            {/* description */}
            {/* <p className="text-justify tracking-wide text-[#88939e]">
            The pride of Ghana.
            </p>
            <Values />
            <Ceo /> */}
            <div className="max-w-7xl mx-4 my-12 flex justify-center lg:mx-auto lg:justify-start ">
              <div>
                {/* <h1 className="text-sunshine tracking-wide font-semibold my-4">
                  About Us
                </h1> */}
                <h1 className="text-black font-bold text-3xl lg:text-5xl md:text-left max-w-xl my-4">
                  Feel The Best Experience{" "}
                  <span className="my-3">With Our Rental Deals</span>
                </h1>
                <div className="flex items-center gap-1 text-sunshine my-4">
                  <GiFlowerEmblem />
                  <div>
                    <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
                    <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
                  </div>
                </div>
                <p className="max-w-md leading-7 md:text-left">
                  Welcome to Imperial Shuttle, where we serve as the vital link
                  between reputable rental car companies and discerning
                  customers. Established with the aim of streamlining the car
                  rental process, we're dedicated to providing a platform that
                  ensures a seamless and satisfying experience for both parties
                  involved.
                </p>
                <p className="max-w-md leading-7 md:text-left my-5">
                  At Imperial Shuttle, we recognize the importance of choice,
                  reliability, and efficiency when it comes to renting a
                  vehicle. Our platform brings together a curated selection of
                  renowned rental car companies, offering a diverse range of
                  vehicles to suit every need and preference.
                </p>
              </div>
            </div>
          </div>
          {/* right Side */}
          <div className="w-full">
            <img src="assets/images/1.png" alt="" className="px-10 py-10" />
          </div>
        </div>
        <div className="pb-20 -mt-11 flex items-center justify-center">
          <div className="max-w-7xl px-5 ">
            <p className="">
              We prioritize transparency and customer satisfaction, striving to
              create a user-friendly environment that simplifies the rental process.
              Whether you're a seasoned traveler, a business professional, or
              someone in need of a temporary vehicle, Imperial Shuttle is here to
              connect you with trusted rental partners.
            </p>
            <p className="my-5">
              Our commitment to excellence extends to our rental car partners,
              ensuring they meet stringent quality standards and provide top-notch
              customer service. By choosing Imperial Shuttle, you're choosing a
              platform that values your time, preferences, and peace of mind.
            </p>
            <p className="">
              Join us in creating a community of satisfied customers and reputable
              rental car companies. Thank you for choosing Imperial Shuttle as your
              go-to destination for seamless and reliable car rentals.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
