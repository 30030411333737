import React from "react";
import { GiFlowerEmblem } from "react-icons/gi";

const NewsLetter = () => {
  return (
    <div className="w-full bg-sunshine text-white py-20 px-5 relative">
      <div className="hidden absolute top-[50%] right-0 translate-y-[-55%] lg:translate-y-[-50%] md:flex justify-end w-1/2">
        <img src="assets/images/range.png" alt="" className="w-[80%] lg:w-full " />
      </div>
      <div className="max-w-7xl mx-5 lg:mx-auto">
        <h1 className="text-lg font-semibold tracking-wide my-4">Newsletter</h1>
        <div className="text-4xl font-bold space-y-2 text-shadow-md">
          <h1>Subscribe To Our Newsletter</h1>
          <h1>For New Updates</h1>
        </div>
        <div className="flex items-center gap-1 text-white my-4">
          <GiFlowerEmblem className="text-xl" />
          <div className="space-y-1">
            <div className="w-10 bg-sunshine border border-white"></div>
            <div className="w-6 bg-sunshine border border-white "></div>
          </div>
        </div>
        {/* input */}
        <div className="bg-white z-50 shadow-2xl text-center py-3 flex items-center justify-between lg:w-2/5 px-3 rounded-xl mt-8">
          <input
            type="text"
            placeholder="Your Email Address..."
            className="h-full w-72 py-2 md:py-3 px-2 text-gray-700 inputMe text-lg !border-2"
          />
          <div className="bg-sunshine text-white font-semibold text-shadow-sm px-5 py-4 shadow-lg rounded-xl cursor-pointer transition duration-300 ease-in-out hover:-translate-x-2">
            Subsrcibe Now
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default NewsLetter;
