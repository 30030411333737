import {GenericRespDataC} from "../interfaces/genericResponse";
import {Connection, RequestTools} from "../request";
import {MyAxiosException} from "../axios";
import HttpConstants from "../constants";
import { RequestConfig } from "../interfaces/requestConfig";
import ApiConstants from "../../../configuration/constants";
import { appSingleton } from "../../../configuration/appUtils";
import { GenericUtils } from "../utils/generic";

export class ApiUtils<D=any> {

    async callApi(connection: Connection<D>, requestConfig: RequestConfig<D>): Promise<GenericRespDataC> {

    let { url, method, headers, params, data, successText, errorText, trackRequestTimeKey } = requestConfig
    headers = {...headers, Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    let _resp: GenericRespDataC;

    let _successText: string = successText ?? "Completed successfully"
    let resp;

    try {

        resp = await new RequestTools(connection, url, false, method, headers, params, data, trackRequestTimeKey)
            .fetchData()
        _resp = this._mapRemoteToResponse(resp,  _successText);

    } catch (error: any) {
        let axiosException = error as MyAxiosException
        if (error.message.serverMsg === HttpConstants.JWT_EXPIRED_MESSAGE){
            let refreshToken = localStorage.getItem('refreshToken')
            let refreshTokenUrl = ApiConstants.apiTokenRefreshUrl + refreshToken
            let refreshTokenResp = await new ApiUtils().callApi(appSingleton.connection,{url: refreshTokenUrl, method: HttpConstants.KEY_POST})
            if (refreshTokenResp.isValid){
                refreshToken = refreshTokenResp.content.newAccessToken
                localStorage.setItem('accessToken', refreshToken || '')
                return await new ApiUtils().callApi(appSingleton.connection, requestConfig)
            }
            localStorage.clear()
            document.location.href="/"
        }
        _resp = {isValid: false, message: errorText ?? axiosException.message}
    }

    return _resp;
}

    _mapRemoteToResponse(data: any, message = ''): GenericRespDataC{
        return {
            isValid: GenericUtils.getDeepMap(data, 'is_valid',  true),
            message: GenericUtils.getDeepMap(data, 'resp_msg', message),
            status: GenericUtils.getDeepMap(data, 'status',  {}),
            content: data.message,
            rawBody: data
        }

    }

}

