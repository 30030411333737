import React from "react";
import { useNavigate } from "react-router-dom";
import { Order } from "../../services/orders/interfaces/order";

const Table = ({ orders }: { orders?: Order[] }) => {
  const navigate = useNavigate()
  return (
    <div className="relative overflow-x-scroll shadow-md sm:rounded-lg">
      <table className="w-full text-lg text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="text-xl">
            <th scope="col" className="px-6 py-3">
              Order Number
            </th>
            <th scope="col" className="px-6 py-3">
              Date created
            </th>
            <th scope="col" className="px-6 py-3">
              Start Date
            </th>
            <th scope="col" className="px-6 py-3">
              Total Amount
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders?.map((order, index) => {
              return (
                  <tr
                  onClick={()=>navigate(`/orders/${order?._id}`)}
                    key={index}
                    className="bg-white cursor-pointer border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">#{order?.orderNumber}</td>                    
                    <td className="px-6 py-4">{`${new Date(
                      order?.createdAt
                    ).getDate()}/${new Date(
                      order?.createdAt
                    ).getMonth()}/${new Date(
                      order?.createdAt
                    ).getFullYear()}`}</td>
                    <td className="px-6 py-4">{`${new Date(
                      order?.startDate
                    ).getDate()}/${new Date(
                      order?.startDate
                    ).getMonth()}/${new Date(
                      order?.startDate
                    ).getFullYear()}`}</td>
                    <td className="px-6 py-4">GHS{order?.totalAmountPaid}</td>
                    <td className="px-6 py-4">
                      <label className="px-5 py-2 bg-[#e7faec] text-[green] rounded-md">
                        {order?.status}
                      </label>
                    </td>
                  </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
