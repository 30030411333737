import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { getValue } from "../../../utilities/utilities";

type Props = {
  label?: string;
  type: string;
  name: string;
  width?: any;
};

const ContactInput: FC<Props> = ({
  label,
  name,type,
  width,
  ...rest
}) => {
  return (
    <div className="!w-full relative">
      <Field id={name} name={name} {...rest}>
        {({ field, form }: any) => {
          const error =  getValue(form.errors, name)
          return (
            <input
              type={type}
              id={name}
              autoComplete="off"
              placeholder={label}
              className={`${error ? "border-[red]" : "border-primary-borderColor"} px-6 py-3 rounded-md shadow-md w-full outline-none border border-transparent focus:ring-0 focus:border-[#ec3323]`}
              {...field} // this must come before value attribute
            />
          );
        }}
      </Field>
      <div className="absolute"><ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} /></div>
    </div>
  );
};

export default ContactInput;
