import React, { FC } from "react"
import { Field, ErrorMessage } from "formik"

type Props = {
  label?: string
  type: string;
  name: string
  width?: number
}

const ContactTextarea: FC<Props> = ({ label, type, name, width, ...rest }) => {
  return (
    <div className="">
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <Field
        id={name}
        name={name}
        component="textarea"
        rows="4"
        placeholder={label}
        className="px-6 py-3 my-4 rounded-md shadow-md w-full outline-none border border-transparent focus:ring-0 focus:border-[#ec3323]"
        {...rest}
      />
      <div className="absolute"><ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} /></div>
    </div>
  )
}

export default ContactTextarea
