import { useNavigate } from "react-router-dom";

const Hero = ({handleScroll}: {handleScroll?: ()=>void}) => {
  const navigate = useNavigate()
  return (
    <div
      className="hero h-[750px] pt-14 bg-cover bg-center"
      style={{
        backgroundImage:
          "url(https://images.pexels.com/photos/638479/pexels-photo-638479.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
      }}
    >
      <div className=" top-0 absolute h-[750px] w-full"></div>
      <div className="py-10 text-center hero-content text-neutral-content backdrop-blur-xl rounded-3xl">
        <div className="">
          <h1 className="max-w-5xl mb-5 text-6xl font-bold text-shadow-xl">
            Welcome to Imperial Shuttle!
          </h1>
          <p className="max-w-md mx-auto my-8 text-4xl tracking-wider text-shadow-md">
          The pride of Ghana.
          </p>
          <button onClick={handleScroll} className="px-5 py-3 text-2xl tracking-wider text-white transition duration-500 rounded-lg shadow-md text-shadow-md hover:shadow-lg hover:-translate-y-1 bg-sunshine">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
