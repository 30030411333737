import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { messageExpired } from "../../store/message/messageSlice";



export default function AppMessage() {
    const message = useAppSelector(state => state.message.message)
    const dispatch = useAppDispatch()

    useEffect(() =>{
        if (message?.description) {
            setTimeout(() => {
                dispatch(messageExpired())
            }, 4000)
        }
    }, [dispatch, message])

    return (
        <>
            {
                message?.description && <div className={` right-1/2 translate-x-1/2 fixed top-1 z-50 border-t-4 ${message.type === "success" ? "border-teal-500 text-teal-900 bg-teal-100": "border-red-600 text-red-800 bg-red-100"} rounded-b  px-4 py-3 shadow-md`} role="alert">
                    <div>
                        <p className="text-sm">{message?.description}</p>
                    </div>
                </div>
            }
        </>
    )
}
