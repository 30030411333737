import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Order } from "../../services/orders/interfaces/order";
import { formatPrice } from "../../utilities/utilities";

// components
const OrderDetailsCard = ({ orderID }: { orderID: string }) => {
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  useEffect(() => {
    let ordersString: Order[] | any = localStorage.getItem("orders");
    let orders = JSON.parse(ordersString);
    let order = orders?.find((orders: any) => {
      return orders?._id === orderID;
    });
    if (order) {
      setSelectedOrder(order);
    }
  }, [orderID]);


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-none rounded-3xl  border-0 my-8">
        <div className="rounded-3xl bg-white px-6 py-6 mb-10 shadow-lg">
          <div className="text-center flex justify-between items-center p-4">
            <h6 className="text-sunshine text-2xl font-semibold">
              Order Details
            </h6>
            <Link to="/orders">
              <button
                className={`bg-sunshine text-white active:bg-lightBlue-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                type="button"
              >
                Orders
              </button>
            </Link>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-0 py-10 pt-0 rounded-3xl bg-white shadow-lg">
          <form>
            <div className="overflow-hidden sm:rounded-md">
              <div className="px-0 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label
                      htmlFor="first-name"
                      className="label !text-sm uppercase bg-slate-300 rounded-full px-2"
                    >
                      <span className="">Customer</span>
                      {selectedOrder?.customer?.isActive ? (
                        <small
                          className={`bg-green-600 text-white rounded-full px-3 py-1`}
                        >
                          Active
                        </small>
                      ) : (
                        <small
                          className={`bg-red-600 text-white rounded-full px-3 py-1`}
                        >
                          Not Active
                        </small>
                      )}
                    </label>
                    <div className="flex flex-col justify-start items-start pl-3">
                      <input
                        disabled
                        type="text"
                        value={`${selectedOrder?.customer?.lastName} ${selectedOrder?.customer?.firstName}`}
                        name="brand"
                        className="text-sunshine"
                      />
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.customer?.phoneNumber}
                      </small>
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.customer?.email}
                      </small>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label htmlFor="year" className="label !text-sm uppercase">
                      Order Number
                    </label>
                    <input
                      disabled
                      type="text"
                      name="year"
                      id="year"
                      value={selectedOrder?.orderNumber}
                      className="text-sunshine pl-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label
                      htmlFor="last-name"
                      className="label !text-sm uppercase"
                    >
                      Pick Up Location
                    </label>
                    <input
                      disabled
                      type="text"
                      name="model"
                      value={selectedOrder?.address?.pickUp}
                      className="text-sunshine pl-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label
                      htmlFor="last-name"
                      className="label !text-sm uppercase"
                    >
                      Drop Off Location
                    </label>
                    <input
                      disabled
                      type="text"
                      name="model"
                      value={selectedOrder?.address?.dropOff}
                      className="text-sunshine pl-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label htmlFor="type" className="label !text-sm uppercase">
                      Driver Details
                    </label>
                    <input
                      disabled
                      type="text"
                      name="year"
                      id="year"
                      value={selectedOrder?.note}
                      className="text-sunshine pl-3"
                    />
                    <div className="flex flex-col justify-start items-start pl-3">
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.driver?.name}
                      </small>
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.driver?.phone}
                      </small>
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.driver?.email}
                      </small>
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.driver?.licenseNumber && <span className="">License Number: {selectedOrder?.driver?.licenseNumber}</span>}
                      </small>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label htmlFor="price" className="label !text-sm uppercase">
                      Price
                    </label>
                    <div className="flex flex-col justify-start items-start pl-3">
                      <input
                        disabled
                        type="text"
                        name="price"
                        id="price"
                        value={formatPrice(
                          selectedOrder?.totalAmountPaid
                            ? selectedOrder?.totalAmountPaid
                            : 0
                        )}
                        className="text-sunshine"
                      />
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.paymentReference}
                      </small>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label
                      htmlFor="phone-number"
                      className="label !text-sm uppercase"
                    >
                      Driver Included
                    </label>
                    <input
                      type="checkbox"
                      name="driverIncluded"
                      checked={selectedOrder?.isDriverInclusive ? true : false}
                      className="w-10 h-10 !border-sunshine !bg-sunshine !text-sunshine !ring-sunshine "
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 space-y-2">
                    <label
                      htmlFor="last-name"
                      className="label !text-sm uppercase bg-slate-300 rounded-full px-2"
                    >
                      <span className="">Owner</span>
                      {selectedOrder?.partner?.isActive ? (
                        <small
                          className={`bg-green-600 text-white rounded-full px-3 py-1`}
                        >
                          Active
                        </small>
                      ) : (
                        <small
                          className={`bg-red-600 text-white rounded-full px-3 py-1`}
                        >
                          Not Active
                        </small>
                      )}
                    </label>
                    <div className="flex flex-col justify-start items-start pl-3">
                      <input
                        disabled
                        type="text"
                        name="insurance"
                        id="last-name"
                        value={`${selectedOrder?.partner?.owner?.lastName} ${selectedOrder?.partner?.owner?.firstName}`}
                        className="text-sunshine"
                      />
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.partner?.phoneNumber}
                      </small>
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.partner?.email}
                      </small>
                      <small className="text-gray-500 text-[13px]">
                        {selectedOrder?.partner?.address}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderDetailsCard;
