import React, { FC } from "react";
import { Order } from "../../../services/orders/interfaces/order";
import InvoiceOrdersTable from "../InvoiceOrdersTable";

interface Props {
  orders: Order[];
}

const Description: FC<Props> = ({
  orders,
}) => {
  return (
   <>
    <div className="w-[100%] flex -mx-1 border-b py-2 items-start">
      <div className="flex-1 px-1">
        <p className="text-gray-800 uppercase tracking-wide text-sm font-bold">
          Description
        </p>
        
      </div>

    </div>
      <div className="px-1 py-5 w-full text-center">
      {orders && <InvoiceOrdersTable orders={orders} />}
      </div>
   </>
  );
};

export default Description;
