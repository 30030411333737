import React, { useEffect, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import {
  buildInitialVehicleDetails,
  VehicleDetails,
} from "../../services/vehicles/interfaces/createVehicleDetails";
import { VehiclesService } from "../../services/vehicles/VehiclesService";
import TabsRender from "../../components/Tabs/Tabs";
import CarDeals from "../../components/CarDeals/CarDeals";
import { useAppSelector } from "../../store/hooks";
import { GiFlowerEmblem } from "react-icons/gi";
import { formatPrice } from "../../utilities/utilities";
import Loader from "../Generics/pulsingLoader/Loader";
const CarDetails = () => {
  const { vehicleList: vehicles, status } = useAppSelector(
    (state) => state.vehicles
  );

  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState(buildInitialVehicleDetails());

  let { vehicleID } = useParams();

  const handleBooking = (vehicle: VehicleDetails) => {
    navigate(`/car-booking/${vehicle._id}`, { state: vehicle });
  };
  useEffect(() => {
    if (vehicleID) {
      VehiclesService.getVehicleById(vehicleID).then((vehicle) => {
        setVehicle(vehicle);
      });
    }
    window.scrollTo(0, 0);
  }, [vehicleID]);

  return (
    <div className="">
      <Banner title="Vehicle Details" />
      <div className="relative  w-full pt-28 px-5">
        {/* BreadCrumbs */}
        <div className="absolute breadcrumbs text-sm antialiased w-fit z-10 -top-6 left-[50%] translate-x-[-50%] px-4 py-3 rounded-lg shadow-xl shadow-sunshine/40 bg-sunshine !text-white">
          <ul>
            <li>
              <Link to="/" className="text-white">
                Home
              </Link>
            </li>
            <li>
              <Link to="/">Vehicle For Rent</Link>
            </li>
            <li>
              <Link to={`/car-details/${vehicleID}`}>Vehicle Details</Link>
            </li>
          </ul>
        </div>

        <>
          {status === "loading" ? (
            <div className="absolute top-[50%] right-[50%]">
              <Loader />
            </div>
          ) : (
            <>
              {/* main content */}
              <div className="max-w-7xl mx-4 md:mx-auto pb-20 grid grid-cols-1 md:grid-cols-5 gap-6">
                {/* carousel */}
                <div className="col-span-2 bg-[#f2f2f2] rounded-xl h-fit">
                  <img
                    src={
                      vehicle?.images[0]
                        ? vehicle?.images[0]
                        : "/assets/images/default-car.webp"
                    }
                    alt=""
                    className="w-full rounded-xl"
                  />
                </div>

                {/* details */}
                <div className="col-span-3">
                  <h1 className="text-5xl text-shadow-sm font-bold tracking-wide">
                    {vehicle?.brand} {vehicle?.model}
                  </h1>
                  <h1 className="text-gray-500 italic text-sm">
                    Owner: {vehicle?.partner?.business?.name}
                  </h1>

                  {/* <div className="flex items-center gap-3 my-2">
              <AiOutlineStar className="text-sunshine text-2xl" />
              <span>
                <span className="text-gray-500 font-semibold">4.5</span>
                <span className="text-sunshine font-semibold">
                  {"  "}( 124 Customer Review )
                </span>
              </span>
            </div> */}

                  <div className="text-sunshine  font-semibold flex items-center my-5 gap-4">
                    <div className="flex items-baseline">
                      <h1 className="text-2xl">
                        {formatPrice(+vehicle?.price)}
                      </h1>
                      <span className="text-sunshine/75">/Day</span>
                    </div>
                    <div className="h-7 border border-gray-500/30" />
                    <div className="flex items-baseline !text-gray-500">
                      <h1 className="text-2xl">
                        {vehicle?.price &&
                          formatPrice(parseFloat(vehicle?.price) * 30)}
                      </h1>
                      <span className="text-gray-500/75">/Month</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* tabs */}
              <div className="max-w-7xl mx-auto pb-28">
                <TabsRender vehicle={vehicle} />
                <div className="ml-4">
                  <button
                    onClick={() => handleBooking(vehicle)}
                    className="font-semibold tracking-tight hover:translate-y-1 transition duration-500 ease-in-out text-center w-fit py-3 my-4 shadow-xl rounded-lg bg-sunshine text-white shadow-sunshine/40 px-5"
                  >
                    Book Your Ride
                  </button>
                </div>
              </div>
            </>
          )}
        </>
        <div className=" w-full bg-[#f7f7f7] py-20">
          <h1 className="text-sunshine tracking-wide font-semibold my-4 text-center">
            Recent Products
          </h1>
          <h1 className="text-5xl font-bold tracking-tight text-shadow-sm text-center">
            Our Related Products
          </h1>
          <div className="flex items-center justify-center gap-1 text-sunshine mt-4 mb-8">
            <div className="justify-end">
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-10 flex justify-end">
                <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
              </div>
            </div>
            <GiFlowerEmblem className="text-2xl" />
            <div>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
            {vehicles &&
              vehicles
                .slice(0, 3)
                .map((vehicle, index) => (
                  <CarDeals
                    key={vehicle?._id}
                    vehicle={vehicle}
                    index={index}
                  />
                ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CarDetails;
