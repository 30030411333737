import React, { FC } from "react";

interface Props {
  title: string,
  description: string,
}

const Offer:FC<Props> = ({title, description}) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="h-2 w-2 rounded-full bg-[#ec3323]"></div>
        <h4 className="text-lg font-semibold">{title}</h4>
      </div>
        <p className=" text-md text-left pl-4">
          {description}
        </p>
    </div>
  );
};

export default Offer;
