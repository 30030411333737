import React from "react";
import { GiFlowerEmblem } from "react-icons/gi";

const Divider = () => {
  return (
    <div className="flex items-center gap-1 text-sunshine mt-1 mb-4 ">
      <GiFlowerEmblem className="text-xl" />
      <div>
        <div className="w-10 bg-sunshine border border-sunshine"></div>
        <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
      </div>
    </div>
  );
};

export default Divider;
