import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* first footer */}
      <div className="w-full bg-[#1d2124] px-5 py-8 text-white">
        <div className="max-w-7xl mx-auto mb-5">
          <h1 className="text-3xl font-bold tracking-wide imp">
            Imperial Shuttle
          </h1>
        </div>
        <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12 justify-between">
          {/* about */}
          {/* <div>
            <div className="my-4">
              <h1 className="text-3xl font-bold tracking-wide imp">
                Company
              </h1>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
            <h1 className="font-bold mt-2">Office Location:</h1>
            <p className="max-w-sm">
              House No S1, Hydraform Est., Off Spintex Road, East Airport,
              Accra.
            </p>
            <h1 className="font-bold mt-2">Digital address:</h1>
            <p className="max-w-sm">GZ-168-8928</p>
            <h1 className="font-bold mt-2">Tel:</h1>
            <p className="max-w-sm">0208154869 / 0246566990</p>
            <p className="max-w-sm"></p>
          </div> */}
          {/* Together */}
          {/* Company Links */}
          <div>
            <div className="my-4">
              <h1 className="text-2xl font-bold align-baseline">Company</h1>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
            </ul>
          </div>
          {/* Explore Links */}
          <div>
            <div className="my-4">
              <h1 className="text-2xl font-bold align-baseline">Explore</h1>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
            <ul>
              <li>
                <Link to="/car-listing">Cars</Link>
              </li>
            </ul>
          </div>
          {/* Policies Links */}
          <div>
            <div className="my-4">
              <h1 className="text-2xl font-bold align-baseline">Policies</h1>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
            <ul>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          {/* Help Links */}
          <div>
            <div className="my-4">
              <h1 className="text-2xl font-bold align-baseline">Help</h1>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
            <ul>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          {/* Gallery */}
          {/* <div>
            <div className="my-4">
              <h1 className="text-2xl font-bold">Our Gallery</h1>
              <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
            <div>
              <div className="grid grid-cols-3 gap-5">
                <img
                  src="/assets/images/listing.jpg"
                  alt=""
                  className=" rounded-lg"
                />
                <img
                  src="/assets/images/listing.jpg"
                  alt=""
                  className=" rounded-lg"
                />
                <img
                  src="/assets/images/listing.jpg"
                  alt=""
                  className=" rounded-lg"
                />
                <img
                  src="/assets/images/listing.jpg"
                  alt=""
                  className=" rounded-lg"
                />
                <img
                  src="/assets/images/listing.jpg"
                  alt=""
                  className=" rounded-lg"
                />
                <img
                  src="/assets/images/listing.jpg"
                  alt=""
                  className=" rounded-lg"
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="max-w-7xl mx-auto py-4">
          <hr className="" />
          {/* <hr className="" />
          <hr className="" />
          <hr className="" /> */}
        </div>
      </div>
      {/* second footer */}
      <div className="w-full bg-[#41494c] text-white">
        <div className="flex md:flex-row flex-col space-y-2 gap-4 justify-between max-w-7xl md:items-center py-2 lg:mx-auto mx-4">
          <div>
            <h3>
              Copyright ©{" "}
              <span className="text-[#fe3227] text-shadow-md antialiased font-semibold tracking-wide impf">
                Imperial Shuttle
              </span>{" "}
              2022. All rights reserved.
            </h3>
          </div>
          {/* socials */}
          <div className="flex gap-4 socials">
            {/* facebook */}
            {/* <div>
              <FaFacebookF />
            </div> */}
            {/* Instagram */}
            <div>
            <Link to='https://www.instagram.com/imperialshuttle_gh/'>
              <FaInstagram />
            </Link>
            </div>
            {/* Twitter */}
            <div>
            <Link to='https://twitter.com/ImperialSGH'>
              <FaTwitter />
            </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
