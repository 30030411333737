import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  completeAccountActivationAndlogin,
  contactUs,
  forgotPassword,
  login,
  register,
  resendActivationActivationCode,
  resetPassword,
} from "./actions";

export interface UserState {
  customerID: string;
  isAuthenticated: boolean;
  token?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  status: "idle" | "loading" | "failed";
  passwordResetstatus: "idle" | "loading" | "failed";
  loginStatus: "idle" | "loading" | "failed";
  errorMessage?: string;
  successMessage?: string;
  registerError?: string;
  loginError?: string;
  activationSent?: boolean;
  activationToken?: string;
  newRegisteredEmail?: string;
  resentActivationCode?: string;
}

const initialState: UserState = {
  customerID: localStorage.getItem("customerID") || "",
  isAuthenticated: localStorage.getItem("accessToken") ? true : false,
  token: localStorage.getItem("accessToken") || "",
  firstName: "",
  lastName: "",
  email: "",
  status: "idle",
  passwordResetstatus: "idle",
  loginStatus: "idle",
  errorMessage: "",
  registerError: "",
  loginError: "",
  successMessage: "",
  activationSent: false,
  activationToken: "",
  newRegisteredEmail: "",
  resentActivationCode: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.activationSent = false;
      state.activationToken = "";
      state.newRegisteredEmail = "";
      state.token = "";   
    },
    loginWithToken: (state, action: PayloadAction<string>) => {
      state.token = action?.payload;
      state.isAuthenticated = true;
      localStorage.setItem("token", action?.payload);
    },
    activationCompleted: (state) => {
      state.activationSent = false;
    },
    resetUserState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.registerError = "";
      state.loginError = "";
      state.status = "idle";
      state.loginStatus = "idle";
      state.passwordResetstatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginStatus = "idle";
        if (action?.payload?.rawBody) {
          state.firstName = action?.payload?.rawBody?.message?.firstName;
          let customerID = action?.payload?.rawBody?.message?.id;
          let { accessToken, refreshToken } = action?.payload?.rawBody?.message;
          state.customerID = customerID;
          if (accessToken && refreshToken) {
            state.isAuthenticated = true;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("customerID", customerID);
          }
        }
        if (!action?.payload?.isValid) {
          state.loginError = action?.payload?.message;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loginError = action?.payload as any;
      })
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = "idle";

        if (action?.payload) {
          state.activationSent = true;
          state.activationToken =
            action.payload.message?.response?.resetPasswordToken;
          state.newRegisteredEmail =
            action?.payload?.message?.response?.email;
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.registerError = action?.payload as any;
      })
      .addCase(resendActivationActivationCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resendActivationActivationCode.fulfilled, (state, action) => {
        state.status = "idle";
        state.resentActivationCode = action?.payload?.content?.msg;
      })
      .addCase(resendActivationActivationCode.rejected, (state, action) => {
        state.status = "idle";
      })
      .addCase(completeAccountActivationAndlogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(completeAccountActivationAndlogin.fulfilled, (state, action) => {
        state.status = "idle";
        if (action?.payload?.rawBody) {
          state.firstName = action?.payload?.rawBody?.message?.firstName;
          state.lastName = action?.payload?.rawBody?.message?.lastName;
          state.email = action?.payload?.rawBody?.message?.email;
          let customerID = action?.payload?.rawBody?.message?.id;
          let { accessToken, refreshToken } = action?.payload?.rawBody?.message;
          state.customerID = customerID;
          if (accessToken && refreshToken) {
            state.isAuthenticated = true;
            localStorage.setItem("firstName", action?.payload?.rawBody?.message?.firstName);
            localStorage.setItem("lastName", action?.payload?.rawBody?.message?.lastName);
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("customerID", customerID);
          }
        }
        if (!action?.payload?.isValid) {
          state.errorMessage = action?.payload?.message;
        }
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "idle";
        if (action?.payload?.rawBody) {
          state.activationToken = action?.payload?.rawBody?.message?.token;
        }
        if (!action?.payload?.isValid) {
          state.errorMessage = action?.payload?.message;
        }
      })
      .addCase(resetPassword.pending, (state) => {
        state.passwordResetstatus = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.passwordResetstatus = "idle";
        if (action?.payload?.rawBody) {
          state.successMessage = action?.payload?.rawBody?.message;
        }
        if (!action?.payload?.isValid) {
          state.errorMessage = action?.payload?.message;
        }
      })
      .addCase(contactUs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.status = "idle";

        if (action?.payload?.message) {
          state.successMessage = action?.payload?.message;
        }
        if (!action?.payload?.isValid) {
          state.errorMessage = action?.payload?.message;
        }
      });
  },
});

export const { loginWithToken, logout, activationCompleted, resetUserState } =
  userSlice.actions;

export default userSlice.reducer;
