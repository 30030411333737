import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { getValue } from "../../../utilities/utilities";

export type Comparator = { key: string; value: string };

type Props = {
  label?: string;
  name: string;
  groupTouched?: string;
  selfTouched?: string;
  options?: Comparator[];
  width?: any;
};

const Select: FC<Props> = ({
  label,
  name,
  groupTouched,
  selfTouched,
  options,
  width,
  ...rest
}) => {
  return (
    <div>
      <Field id={name} name={name} {...rest}>
        {({ field, form, meta }: any) => {
          const error =  getValue(form.errors, name)
          
          return (
            <select
              id={name}
              className={`${
                width === "small" ? "w-[100%]" : " "
              } border-1  ${error ? "border-[red]" : "border-primary-borderColor"} text-primary-textMuted placeholder-primary-textMuted text-xs rounded-md`}
              {...field}
              onChange={(e) => {
                form.handleChange(e);
                if (e?.target?.value.trim() === "") {
                  form.setFieldValue(selfTouched, false);
                } else {
                  form.setFieldValue(selfTouched, true);
                }
              }}
            >
              <option value="">Select</option>
              {options?.map((option: any) => {
                return (
                  <option key={option?.key} value={option?.value}>
                    {option?.key}
                  </option>
                );
              })}
            </select>
          );
        }}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-[12] text-[red]">{msg}</div>}
      />
    </div>
  );
};

export default Select;
