import React, { useState } from 'react';

interface Faq {
  question: string;
  answer: string;
}

interface FaqAccordionProps {
  faqs: Faq[];
}

const FaqAccordion: React.FC<FaqAccordionProps> = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleAccordionClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="w-full mx-auto mt-8 text-left">
      <div>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <div
              className={`border rounded p-4 cursor-pointer ${
                activeIndex === index ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleAccordionClick(index)}
            >
              <h2 className="text-xl font-medium">{faq.question}</h2>
            </div>
            {activeIndex === index && (
              <div className="bg-gray-100 p-4 mt-2">
                <p className="text-gray-700">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqAccordion;
