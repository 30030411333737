import { appSingleton } from "../../configuration/appUtils";
import ApiConstants from "../../configuration/constants";
import { ApiUtils } from "../http/api/rest";
import HttpConstants from "../http/constants";
import { OrderDetails } from "./interfaces/orderDetails";

export class VehiclesService {
  static async getVehicles(page: number, limit: number) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: `${ApiConstants.apiGetVehiclesUrl}page=${page}&limit=${limit}`,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response;
  }
  static async getVehicleById(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetVehicleById + id,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response?.rawBody?.data;
  }
  static async removeVehicleFromCart(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiRemoveOder + id,
      method: HttpConstants.KEY_DELETE,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response?.rawBody?.data;
  }
  static async getCustomerInvoice(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetCustomerInvoice + id,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response?.rawBody?.data;
  }
  static async getInvoiceById(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetInvoice + id,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response?.rawBody?.data;
  }
  static async getInvoiceByInvoiceNumber(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetInvoiceByInvoiceNumber + id,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response?.rawBody?.data;
  }
  static async createCustomerInvoice(orderDetails: {
    customer: string;
    orders: string[];
  }) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiCreateCustomerInvoiceUrl,
      method: HttpConstants.KEY_POST,
      data: orderDetails,
    });
    return response?.rawBody?.message;
  }
  static async bookVehicle(orderDetails: OrderDetails) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiCreateOrderUrl,
      method: HttpConstants.KEY_POST,
      data: orderDetails,
    });
    if (response?.isValid) {
      return response?.rawBody?.message;
    } else {
      return response?.message;
    }
  }
  static async getVehicleBrands() {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetAllBrands,
      method: HttpConstants.KEY_GET,
    });
    return response?.rawBody?.data;
  }
  static async getModelByBrand(data: {brand: string, year: number}) {
      const response = await (new ApiUtils()).callApi(appSingleton.connection, {
          url: ApiConstants.apiGetModelByBrandUrl,
          method: HttpConstants.KEY_POST,
          data
      })
      return response?.rawBody?.data
  }
  static async getVehicleByFilters(query: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetVehiclesUrl + query,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response;
  }

  static async getYears() {
    const response = await (new ApiUtils()).callApi(appSingleton.connection, {
      url: ApiConstants.apiGetYearsUrl,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    })
    return response?.rawBody?.data
  }
  static async getType() {
    const response = await (new ApiUtils()).callApi(appSingleton.connection, {
      url: ApiConstants.apiGetTypeUrl,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    })
    return response?.rawBody?.data
  }
}
