import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

const Checkout = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto h-fit px-4 py-12 bg-white text-black relative">
        {/* Heading */}
        <div className="my-12">
          <h1 className="text-xl font-medium tracking-wide ">Checkout</h1>
          {/* BreadCrumbs */}
          <div className="breadcrumbs text-sm antialiased">
            <ul>
              <li>
                <Link to="/" className="text-gray-500">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/cart">cart</Link>
              </li>
              <li>
                <Link to="/cart/checkout">checkout</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Login section */}
        <div>
          {/* Login Heading */}
          <div className="my-8">
            <h1 className="text-md font-semibold tracking-wide antialiased">
              Your Cart Items
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
