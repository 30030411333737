import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";


export function isTokenActive(token: any) {
  if (!token) return false;
  const expirationDate = getTokenExpirationDate(token);
  const now = Math.floor(Date.now() / 1000);
  return expirationDate > now;
}

export function getTokenExpirationDate(encodedToken: any) {
  const token: any = jwt_decode(encodedToken);
  if (!token?.exp) {
    return null;
  }
  return token.exp;
}


const AuthVerify = (props: any) => {
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("accessToken") || "";

    if (token) {
      if (!isTokenActive(token)) {
        props.logOut();
      }
    }
  }, [location, props]);

  return null;
};

export default AuthVerify;
