import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { VehiclesService } from "../../services/vehicles/VehiclesService";
import {
  emptyCart,
  getCustomerInvoice,
  removeFromCart,
  removeVehicleFromCart,
} from "../../store/app/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  calculateTotalCartPrice,
  formatDate,
  formatPrice,
  formatTime,
} from "../../utilities/utilities";

const Cart = () => {
  const { vehicles } = useAppSelector((state) => state.app.cart);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const checkoutHandler = useCallback(() => {
    (async function () {
      if (vehicles.length > 0) {
        const customerID = vehicles[0]?.details?.response.customer;
        let orderIDs = [];
        for (const value of vehicles) {
          orderIDs.push(value?.details?.response?._id);
        }

        const orderDetails = {
          customer: customerID,
          orders: orderIDs,
        };
        const data = await VehiclesService.createCustomerInvoice(orderDetails);
        if (data.msg === "Invoice created successfully") {
          dispatch(emptyCart([]));
          dispatch(getCustomerInvoice(data?.response?.customer));
          let authorizationUrl = data?.pay?.checkout_url;
          document.location = authorizationUrl;
        } else {
          alert("request failed " + data);
        }
      }
    })();
  }, [dispatch, vehicles]);

  return (
    <>
      <div className="relative px-4 py-12 mx-auto text-black bg-white max-w-7xl h-fit">
        {/* Heading */}
        <div className="my-12">
          <h1 className="text-xl font-medium tracking-wide ">Shopping Cart</h1>
          {/* BreadCrumbs */}
          <div className="text-sm antialiased breadcrumbs">
            <ul>
              <li>
                <Link to="/" className="text-gray-500">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/cart">cart</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Login section */}
        <div>
          {/* Login Heading */}
          <div className="my-8">
            <h1 className="antialiased font-semibold tracking-wide text-md">
              BOOKING SUMMARY
            </h1>
          </div>

          {/* Cart Items */}
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr className="cart_head">
                  <th></th>
                  <th>CAR IMAGE</th>
                  <th>CAR DETAILS</th>
                  <th>PRICE</th>
                  <th>NUMBER OF DAYS</th>
                  <th>TOTAL </th>
                </tr>
              </thead>
              <tbody className="relative">
                {vehicles && vehicles?.length > 0 ? (
                  vehicles?.map((vehicle, index) => {
                    return (
                      <tr className="relative" key={vehicle?.vehicle?._id}>
                        <th>{index + 1}</th>
                        <td className="">
                          <img
                            src={
                              vehicle?.vehicle?.images[0]
                                ? vehicle?.vehicle?.images[0]
                                : "/assets/images/default-car.webp"
                            }
                            alt="Car Img"
                            className="w-40"
                          />
                        </td>
                        <td className="">
                          <span>
                            <h1 className="w-full text-lg font-medium">
                              {` ${vehicle?.vehicle?.brand}  ${vehicle?.vehicle?.type}`}
                            </h1>
                            <p className="font-bold text-black">
                              Pick up Date:{" "}
                              <span className="font-normal">
                                {formatDate(
                                  vehicle?.details?.response?.startDate
                                )}
                              </span>
                            </p>
                            <p className="font-bold text-black">
                              Pick up Time:{" "}
                              <span className="font-normal">
                                {formatTime(
                                  vehicle?.details?.response?.startDate
                                )}
                              </span>
                            </p>
                            <p className="font-bold text-black">
                              Drop off Date:{" "}
                              <span className="font-normal">
                                {formatDate(
                                  vehicle?.details?.response?.endDate
                                )}
                              </span>
                            </p>
                            <p className="font-bold text-black">
                              Drop off Time:{" "}
                              <span className="font-normal">
                                {formatTime(
                                  vehicle?.details?.response?.endDate
                                )}
                              </span>
                            </p>
                            <p className="font-bold text-black">
                              Pick up Location:{" "}
                              <span className="font-normal">
                                {vehicle?.details?.response?.address?.pickUp}
                              </span>
                            </p>
                            <p className="font-bold text-black">
                              Drop off Location:{" "}
                              <span className="font-normal">
                                {vehicle?.details?.response?.address?.dropOff}
                              </span>
                            </p>
                          </span>
                        </td>
                        <td>{formatPrice(+vehicle?.vehicle?.price)}</td>
                        <td>
                          {vehicle?.details?.response?.numberOfDaysOrderedFor}
                        </td>
                        {/* we need to discuss the price calculation either by day or per hour or minutes */}
                        <td>
                          {formatPrice(
                            +vehicle?.details?.response
                              ?.numberOfDaysOrderedFor *
                              +vehicle?.vehicle?.price
                          )}
                        </td>
                        {/* <td>{formatPrice(getDatesDifference(vehicle?.details?.response?.startDate, vehicle?.details?.message?.response?.endDate) * +vehicle?.vehicle?.price)}</td> */}
                        <span
                          onClick={() => {
                            dispatch(removeFromCart(vehicle?.vehicle?._id));
                            dispatch(
                              removeVehicleFromCart(
                                vehicle?.details?.response?._id
                              )
                            );
                          }}
                          className="absolute flex items-center justify-center px-3 py-1 text-white rounded-full cursor-pointer right-8 top-5 bg-sunshine hover:shadow-md"
                        >
                          x
                        </span>
                      </tr>
                    );
                  })
                ) : (
                  <div className="my-5">Your cart is empty</div>
                )}
              </tbody>
            </table>
          </div>

          {/* cart buttons */}
          <div className="flex justify-between w-full my-12 shopping_button">
            <div className="flex gap-10 shopping_button">
              <button
                onClick={() => {
                  dispatch(emptyCart([]));
                }}
              >
                CLEAR SHOPPING CART
              </button>
            </div>
            <button onClick={() => navigate("/car-listing")}>
              CONTINUE SHOPPING
            </button>
          </div>
          {/* shopping summary */}

          <div className="flex justify-end ">
            <div className="bg-[#efefef] rounded-md py-12 px-8">
              {/* sub total */}
              <div className="flex items-center justify-end gap-4 font-semibold"></div>
              <div className="flex items-baseline justify-end gap-4 font-semibold">
                <h1 className="text-2xl">Grand Total</h1>
                <span className="text-lg text-sunshine">
                  {formatPrice(calculateTotalCartPrice(vehicles))}
                </span>
              </div>
              <hr className="my-2" />
              <button
                disabled={
                  vehicles?.length === 0 ||
                  calculateTotalCartPrice(vehicles) <= 0
                }
                className="px-5 py-2 my-4 text-white bg-corn/70 hover:shadow-md disabled:cursor-not-allowed"
                onClick={() => {
                  checkoutHandler();
                }}
              >
                PROCEED TO CHECKOUT
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
