import React, { FC } from "react";
import { formatDateTime } from "../../../utilities/utilities";

interface Props {
  invoiceNumber: string;
  invoiceDate: string;
  customer: string;
}

const Meta: FC<Props> = ({ invoiceDate, invoiceNumber, customer }) => {
  return (
    <div className="w-[100%] flex mb-8 justify-between">
      <div className="w-full">
        <div className="mb-2 md:mb-1 flex flex-row md:flex items-center">
          <label className="w-32 text-gray-800 block font-bold text-sm uppercase tracking-wide">
            Customer Name
          </label>
          <span className="mr-4 inline-block hidden md:block">:</span>
          <div className="flex-1">
            <input
              disabled
              readOnly
              className="bg-gray-200 appearance-none rounded w-72 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
              id="inline-full-name"
              type="text"
              placeholder={customer}
              x-model="invoiceNumber"
            />
          </div>
        </div>
        <div className="mb-2 md:mb-1 flex flex-row md:flex items-center">
          <label className="w-32 text-gray-800 block font-bold text-sm uppercase tracking-wide">
            Invoice No.
          </label>
          <span className="mr-4 inline-block hidden md:block">:</span>
          <div className="flex-1">
            <input
              disabled
              readOnly
              className="bg-gray-200 appearance-none rounded w-72 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
              id="inline-full-name"
              type="text"
              placeholder={invoiceNumber}
              x-model="invoiceNumber"
            />
          </div>
        </div>

        <div className="mb-2 md:mb-1 flex flex-row md:flex items-center">
          <label className="w-32 text-gray-800 block font-bold text-sm uppercase tracking-wide">
            Invoice Date
          </label>
          <span className="mr-4 inline-block hidden md:block">:</span>
          <div className="flex-1">
            <input
              disabled
              className="bg-gray-200 appearance-none rounded w-72 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white js-datepicker"
              type="text"
              id="datepicker1"
              placeholder={invoiceDate? formatDateTime(invoiceDate) : ""}
              x-model="invoiceDate"
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meta;
