import { FaQuoteRight } from "react-icons/fa";
import { GiFlowerEmblem } from "react-icons/gi";
import Ratings from "../Ratings";

const Clients = () => {
  return (
    <div className="bg-white w-full py-28 px-5 text-center">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-sunshine font-semibold tracking-wide my-4">
          Testimonials
        </h1>
        <h1 className="text-3xl md:text-5xl tracking-tight text-shadow-sm font-bold mx-1">
          What Our Clients Say
        </h1>
        <div className="flex items-center justify-center gap-1 text-sunshine my-4">
          <div className="justify-end">
            <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
            <div className="w-10 flex justify-end">
              <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
            </div>
          </div>
          <GiFlowerEmblem className="text-2xl" />
          <div>
            <div className="w-10 bg-sunshine border border-sunshine mt-1"></div>
            <div className="w-6 bg-sunshine border border-sunshine mt-1"></div>
          </div>
        </div>
        {/* clients */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 my-12 client text-center mx-5 lg:mx-auto">
          {/* first */}
          <div className="text-center ">
            {/* stars */}
            <Ratings stars={["1", "2", "3"]} />
            <p className="my-2 text-gray-500">
            I always use Imperial Shuttle when I go to Ghana for my annual vacation and I am 
always satisfied with their service.
            </p>
            <p className="text-sunshine font-semibold">Customer</p>

            <FaQuoteRight className="text-6xl absolute text-sunshine/40 right-5 bottom-5" />
          </div>
          {/* second */}
          <div className="text-center ">
            {/* stars */}
            <Ratings stars={["1", "2", "3", "4"]} />
            <p className="my-2 text-gray-500">
            Our management has an agreement with them so we always use them for our 
conferences all over Ghana.
            </p>
            <p className="text-sunshine font-semibold">Customer</p>
            <FaQuoteRight className="text-6xl absolute text-sunshine/40 right-5 bottom-5" />
          </div>
          {/* third */}
          <div className="text-center ">
            {/* stars */}
            <Ratings stars={["1", "2", "3"]} />
            <p className="my-2 text-gray-500">
            They have links with foreign rentals and tour companies so our pickup and drop-off to and from the airport were well coordinated.          </p>
            
            <p className="text-sunshine font-semibold">Customer</p>

            <FaQuoteRight className="text-6xl absolute text-sunshine/40 right-5 bottom-5" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 my-12 client text-center mx-5 lg:mx-auto">
          {/* first */}
          <div className="text-center ">
            {/* stars */}
            <Ratings stars={["1", "2", "3", "4"]} />
            <p className="my-2 text-gray-500">
            As for me and my family, we will always keep faith with Imperial shuttle for their 
efficient and reliable services.
            </p>
            <p className="text-sunshine font-semibold">Customer</p>

            <FaQuoteRight className="text-6xl absolute text-sunshine/40 right-5 bottom-5" />
          </div>
          {/* second */}
          <div className="text-center ">
            {/* stars */}
            <Ratings stars={["1", "2", "3", "4"]} />
            <p className="my-2 text-gray-500">
            Rain or shine I will always use them and recommend them to you.
            Our one-week engagement with them for our nation tour was splendid. 
            </p>
            <p className="text-sunshine font-semibold">Customer</p>

            <FaQuoteRight className="text-6xl absolute text-sunshine/40 right-5 bottom-5" />
          </div>
          {/* third */}
          <div className="text-center ">
            {/* stars */}
            <Ratings stars={["1", "2", "3", "4"]} />
            <p className="my-2 text-gray-500">
            I had the best experience with Imperial Shuttle from start to end.
            </p>
            <p className="text-sunshine font-semibold">Customer</p>

            <FaQuoteRight className="text-6xl absolute text-sunshine/40 right-5 bottom-5" />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Clients;
