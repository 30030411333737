import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useAppDispatch } from "../../store/hooks";
import { removeFromCart } from "../../store/app/appSlice";
import { formatPrice } from "../../utilities/utilities";

const ModalCartItem = ({
  vehicle,
  handlePrice,
}: {
  vehicle: any;
  handlePrice: () => void;
}) => {
  const [item, setItem] = useState(true);
  const dispatch = useAppDispatch();
  const color = vehicle?.vehicle?.features?.type?.color


  const removeItem = (id: string) => {
    handlePrice();
    dispatch(removeFromCart(id));
  };
  return (
    <div
      className={`w-full rounded shadow-xl border border-black/10 pt-2 pb-4 px-2  ${
        item ? "block" : "hidden"
      }`}
    >
      <header className="flex items-center justify-between pb-2">
        <h1 className="text-md text-lg">{` ${vehicle?.vehicle?.brand}  ${vehicle?.vehicle?.type}`}</h1>
        <div
          className="cursor-pointer text-lg rounded-full p-1 border hover:scale-110"
          onClick={() => removeItem(vehicle?.vehicle?._id)}
        >
          <AiOutlineClose />
        </div>
      </header>
      <main>
        <div className="grid grid-cols-3 gap-2">
          <div className="bg-black/20 h-24 flex items-center justify-center">
            <img
              src={vehicle?.vehicle?.images[0] ? vehicle?.vehicle?.images[0] : "/assets/images/default-car.webp"}
              alt="Drink"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="col-span-2 flex flex-col gap-3">
            <span className="flex justify-start items-center">
              Status:{" "}
              <span
                className={`inline-block h-6 w-6 bg-[${color}] ml-5 rounded-full`}
              >{vehicle?.vehicle?.status}</span>
            </span>
            <span>Fuel: {vehicle?.vehicle?.features?.type?.fuel}</span>
          </div>
        </div>
        <hr className="my-2" />
        <div className="grid grid-cols-3 text-center">
          <div>
            <h2>Per Day</h2>
            <p>{formatPrice(vehicle?.vehicle?.price)}</p>
          </div>
          <div>
            <h2>Days</h2>
            <p>{vehicle?.details?.response?.numberOfDaysOrderedFor}</p>
          </div>
          <div>
            <h2>Total</h2>
            <p>{formatPrice(+vehicle?.details?.response?.numberOfDaysOrderedFor * vehicle?.vehicle?.price)}</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ModalCartItem;
