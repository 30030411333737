import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VehicleDetails } from "../../services/vehicles/interfaces/createVehicleDetails";
import { OrderDetails } from "../../services/vehicles/interfaces/orderDetails";
import { VehiclesService } from "../../services/vehicles/VehiclesService";
import { Vehicle, VehicleResponse } from "../../types/carList";

export interface VehiclesState {
  vehicleList: Vehicle[];
  status: "idle" | "loading" | "failed";
  fetched: boolean;
  noInternet: boolean;
}

const initialState: VehiclesState = {
  vehicleList: [],
  status: "idle",
  fetched: false,
  noInternet: false,
};

export const getVehicles = createAsyncThunk(
  "vehicles/getVehicles",
  async ({page, limit}: {page:number, limit:number}) => {
    const response = await VehiclesService.getVehicles(page, limit);
    const vehicles: VehicleResponse = response?.rawBody?.data
    return vehicles;
  }
);
export const bookVehicle = createAsyncThunk(
  "vehicles/bookVehicle",
  async (orderDetails: OrderDetails) => {
    return VehiclesService.bookVehicle(orderDetails);
  }
);
export const fetchVehicleByFilters = createAsyncThunk(
  "vehicles/fetchVehicleByFilters",
  async ({query, page, limit}:{query: string, page:number, limit:number}) => {
    const response = await VehiclesService.getVehicles(page, limit);
    const vehicles: VehicleResponse = response?.rawBody?.data
    const filterResponse = await VehiclesService.getVehicleByFilters(query)
    const filteredVehicles: VehicleResponse = filterResponse?.rawBody?.data
    return filteredVehicles.docs.length > 0
      ? filteredVehicles
      : vehicles;
  }
);

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setNoInternet: (state, action: PayloadAction<boolean>) => {
      state.noInternet = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehicles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.status = "idle";
        state.vehicleList = action?.payload.docs;
        state.fetched = true;
      })
      .addCase(getVehicles.rejected, (state, action) => {
        state.status = "idle";
      })
      .addCase(bookVehicle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bookVehicle.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(fetchVehicleByFilters.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVehicleByFilters.fulfilled, (state, action) => {
        state.status = "idle";

        state.vehicleList = action?.payload?.docs;
        // state.fetched = true
      });
  },
});
export const { setNoInternet } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
