import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FiCalendar } from "react-icons/fi";
import { getValue } from "../../../utilities/utilities";

type Props = {
  label?: string;
  name: string;
  width?: number;
};


const Datepicker: FC<Props> = ({
  label,
  name,
  width,
  ...rest
}) => {
  return (
    <div className="w-full date">
      <Field name={name} {...rest} className="border-2 border-[red]" >
        {({ field, form }: any) => {
          const { setFieldValue } = form;
          const { value } = field;
          const error =  getValue(form.errors, name)
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                className={`${error ? "border-[red] w-full" : "w-full"}`}
                components={{ OpenPickerIcon: FiCalendar }}
                renderInput={(params) => {
                  return (
                    <TextField
                      className={`${error ? "border-[red]" : ""}`}
                      sx={{
                        svg: { color: "#9CA3AF", fontSize: "14px", marginLeft: "-15px", },
                        input: { color: "#9CA3AF", padding: "8px", width: "100%", fontSize: "14px", backgroundColor: "white" },
                        label: { color: "#9CA3AF", fontSize: "12px" },
                        [`& fieldset`]: {
                          borderRadius: 0,
                          border: "2px solid #f3f3f3",
                        },
                        ":focus": {
                          border: "2px solid red"
                        },
                        ":hover:not(.Mui-disabled):before": {
                          borderColor: "#f3f3f3"
                        }
                      }}
                      InputLabelProps={{ shrink: false }}
                      size="small"
                      hiddenLabel
                      {...params}
                    />
                  );
                }}
                ampm={true}
                value={value}
                minDate={new Date()}
                {...field}
                {...rest}
                inputFormat={"yyyy/MM/dd:hh:mm:ss"}
                views={["year", "month", "day", "hours", "minutes", "seconds"]}
                label={value === null && label}
                onChange={(newDate: any) => {
                  setFieldValue(name, newDate);
                }}
              ></DateTimePicker>
            </LocalizationProvider>
          );
        }}
      </Field>
      <ErrorMessage name={name} render={msg => <div className="text-[12] text-[red]">{msg}</div>}  />
    </div>
  );
};

export default Datepicker;
