import React, { useEffect, useMemo, useState } from "react";
import Banner from "../../components/Banner/Banner";
import Table from "../../components/Table/Table";
import { Order } from "../../services/orders/interfaces/order";
import { OrderService } from "../../services/orders/OrderService";
import { useAppSelector } from "../../store/hooks";
import Loader from "../Generics/pulsingLoader/Loader";
import Pagination from "../../components/Pagination/Pagination";

const options = [
  { key: "Completed", value: "COMPLETED" },
  { key: "Paid", value: "PAID" },
  { key: "Pending", value: "PENDING" },
  { key: "All", value: "ALL" },
];

const Orders = () => {
  const [orders, setOrders] = useState<Order[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const customerID = useAppSelector((state) => state.user.customerID);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nPages, setNPages] = useState<number>(0);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      if (customerID) {
        const response = await OrderService.getOrders(customerID, currentPage);
        setOrders(response?.data?.docs);
        setNPages(response?.data?.totalPages);
        localStorage.setItem("orders", JSON.stringify(response?.data?.docs));
      }
      setIsLoading(false);
    };
    fetchOrders();

    return () => setIsLoading(false);
  }, [customerID, currentPage]);

  const handleStatusChange = (e: any) => {
    setStatus(e.target.value);
  };

  const ordersByStatus = useMemo(() => {
    let output = orders?.map((a) => a);
    if (status) {
      output = output?.filter(
        (order) => order?.status?.toLowerCase() === status?.toLowerCase()
      );
    }
    return output;
  }, [orders, status]);

  const showAll = status?.toLowerCase() === "all" || ""

  const showOrders =
    (orders && orders?.length > 0) ||
    (ordersByStatus && ordersByStatus?.length > 0) ? (
      <Table orders={!showAll ? ordersByStatus : orders} />
    ) : (
      <div className="absolute top-10">Orders are not available</div>
    );

  return (
    <div>
      <Banner title="Orders" />
      <div className="pb-20 bg-[#f2f2f2] h-fit">
        <div className="flex justify-between item-center px-20 py-8 space-x-5">
          <div className="">
            <h2 className="text-2xl md:text-4xl font-bold tracking-wide text-[#ec3323]">
              Orders
            </h2>
            <p className="text[#88939e]">Here is your Order list data</p>
          </div>
          <div className="">
            <span className="border border-white py-3 px-1 rounded-lg">
              <select
                className="w-full px-3 py-2 border-r-4 border-white bg-white uppercase text-gray-500 font-bold my-1  rounded-lg outline-none"
                id="status"
                defaultValue={"DEFAULT"}
                onChange={handleStatusChange}
              >
                <option value="DEFAULT" selected disabled>
                  STATUS
                </option>
                {options &&
                  options?.map((option) => {
                    return (
                      <option value={option.value} key={option.key}>
                        {option.key}
                      </option>
                    );
                  })}
              </select>
            </span>
          </div>
        </div>
        <div className="relative py-8 px-20">
          {isLoading ? (
            <div className="absolute top-0 right-[50%]">
              <Loader />
            </div>
          ) : (
            showOrders
          )}
        </div>
            {!isLoading && nPages > 1 && (
              <Pagination
                currentPage={currentPage}
                nPages={nPages}
                setCurrentPage={setCurrentPage}
              />
            )}
      </div>
    </div>
  );
};

export default Orders;
