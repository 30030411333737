export interface IBank {
  id: string;
  name: string;
  code: string;
  type: string;
  number: string;
}
export interface PartnerDetails {
  id: string;
  owner: {
    firstName: string;
    lastName: string;
  };
  business: {
    name: string;
    location: string;
    accountType: string;
    momo?: IBank;
    bank?: IBank;
  };
  email: string;
  password?: string;
  accountType?: string;
  companyTin?: string;
  certOfIncorporation?: string;
  address?: string;
  phoneNumber: string;
  image?: string;
  ghanaCard?: string;
}
export interface VehicleDetails {
  _id: string;
  partner: PartnerDetails;
  bookedUntil: string;
  brand: string;
  location: string;
  insuranceType: string;
  model: string;
  type: string;
  year: string;
  price: string;
  purpose: string;
  status: string;
  images: string[];
  driverCost: number;
  driverIncluded: boolean;
  registrationNumber: string;
  features: {
    type: {
      color: string;
      airCondition: boolean;
      insurance: string;
      transmission: string;
      fuel: string;
      hasWifi: boolean;
      numberOfSeats: number;
    };
  };
}

export interface Vehicle {
  vehicle: string;
  quantity: number;
  totalPrice: number;
}
export interface VehicleSelected {
  vehicle: {
    _id: string;
    partner: PartnerDetails;
    brand: string;
    model: string;
    type: string;
    year: string;
    price: string;
    purpose: string;
    images: string[];
    driverIncluded: boolean;
    registrationNumber: string;
    features: {
      type: {
        color: string;
        airCondition: boolean;
        insurance: string;
        transmission: string;
        fuel: string;
        hasWifi: boolean;
        numberOfSeats: number;
      };
    };
  };
  details: {
    msg: string;
    response: {
      hasPaid: boolean;
      status: string;
      isDriverInclusive: boolean;
      driver: {};
      isDeleted: boolean;
      _id: string;
      customer: string;
      vehicle: string;
      startDate: string;
      endDate: string;
      address: {
        dropOff: string;
        pickUp: string;
      };
      orderNumber: string;
      partner: {
        _id: string;
        email: string;
        phoneNumber: string;
      };
      totalAmountPaid: number;
      numberOfDaysOrderedFor: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
  };
}

export const buildInitialParnerDetails = () => {
  let initialDetails: PartnerDetails;
  initialDetails = {
    id: "",
    owner: {
      firstName: "",
      lastName: "",
    },
    business: {
      name: "",
      location: "",
      accountType: "Momo",
      momo: {
        id: "",
        name: "",
        code: "",
        type: "",
        number: "",
      },
      bank: {
        id: "",
        name: "",
        number: "",
        code: "",
        type: "",
      },
    },
    accountType: "",
    ghanaCard: "",
    email: "",
    companyTin: "",
    certOfIncorporation: "",
    address: "",
    password: "",
    phoneNumber: "",
    image: "",
  };
  return initialDetails;
};

export const buildInitialVehicleDetails = () => {
  let initialDetails: VehicleDetails;
  initialDetails = {
    _id: "",
    partner: buildInitialParnerDetails(),
    bookedUntil: "",
    brand: "",
    location: "",
    insuranceType: "",
    model: "",
    type: "",
    year: "",
    price: "", // in GHS
    purpose: "",
    status: "",
    images: [],
    driverIncluded: false,
    driverCost: 0,
    registrationNumber: "",
    features: {
      type: {
        color: "",
        airCondition: false,
        insurance: "",
        transmission: "",
        fuel: "",
        hasWifi: false,
        numberOfSeats: 0,
      },
    },
  };
  return initialDetails;
};
