import { useParams } from "react-router-dom";
import OrderDetailsCard from "./OrderDetailsCard";

const OrderDetails = () => {
  let { orderID } = useParams();
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-full mx-32 ">
          <OrderDetailsCard orderID={orderID || ''}/>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
