import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { getValue } from "../../../utilities/utilities";

type Props = {
  label?: string;
  type: string;
  name: string;
  width?: any;
  min?: number;
};

const Input: FC<Props> = ({
  label,
  name,type,
  width,
  min,
  ...rest
}) => {
  return (
    <div className="!w-full relative">
      <Field id={name} name={name} {...rest}>
        {({ field, form }: any) => {
          const error =  getValue(form.errors, name)
          return (
            <input
              type={type}
              id={name}
              min={min}
              autoComplete="off"
              placeholder={label}
              className={`appearance-none shadow-sm relative block w-full px-3 py-4 border ${error ? "border-[red]" : "border-primary-borderColor"} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#112046] focus:border-[#112046] focus:z-10 sm:text-sm`}
              {...field} // this must come before value attribute
            />
          );
        }}
      </Field>
      <div className="absolute"><ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} /></div>
    </div>
  );
};

export default Input;
