import React, {FC} from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    printInvoiceHandler: ()=>void
}

const Header:FC<Props> = ({printInvoiceHandler}) => {

  const navigate = useNavigate()
  return (
    <div className="flex justify-between">
      <h2 className="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">
        Invoice
      </h2>
      <div>
        <div className="flex items-center space-x-3">
            <span onClick={()=>{
              navigate(-1)
              }} className="text-white rounded-full cursor-pointer bg-sunshine px-3 py-1" >All Invouces</span>
          <div onClick={printInvoiceHandler} className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-printer"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
              <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
              <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
              <rect x="7" y="13" width="10" height="8" rx="2" />
            </svg>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Header;
