import React, { useEffect, useRef, useState } from "react";
import Offer from "../../components/Offer/Offer";
import { useAppDispatch } from "../../store/hooks";
import CarType from "../../components/CarType/CarType";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/Hero/Hero";
import { GiFlowerEmblem } from "react-icons/gi";
import Clients from "../../components/Clients/Clients";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import VehicleList from "./VehicleList";
import { peopleLikes } from "../../components/Values/data";
import FaqAccordion from "../../components/FAQs/FaqAccordion";
import { faqs } from "../../components/FAQs/data";
import Loader from "../Generics/pulsingLoader/Loader";
import { getUniqueValues } from "../../utilities/utilities";
import { Vehicle } from "../../types/carList";
import ApiConstants from "../../configuration/constants";
import axios from "axios";
import Pagination from "../../components/Pagination/Pagination";

const Home = () => {
  const dispatch = useAppDispatch();

  const topDealsRef: any = useRef(null);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nPages, setNPages] = useState<number>(0);
  const [totalDocs, setTotalDocs] = useState<number>(0);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>();

  const scrollToBottom: any = (): void => {
    topDealsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingVehicles(true);
        // Construct query string
        let query = `${
          ApiConstants.baseUrlCarsAPI
        }/vehicles?page=${currentPage}&limit=${6}`;
        // Make API call with constructed query
        const response = await axios.get(query);
        // Process response as needed
        setVehicles(response?.data?.data?.docs);
        setNPages(response?.data?.data?.totalPages);
        setTotalDocs(response?.data?.data?.totalDocs);
        setIsLoadingVehicles(false);
      } catch (error) {
        setIsLoadingVehicles(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, currentPage]);
  return (
    <>
      <div className="h-fit">
        <Hero handleScroll={scrollToBottom} />
        <div className="relative px-5 py-16 text-center bg-white h-fit">
          {/* <Testimonials /> */}
          <div className="mt-[20rem] md:mt-28 md:mb-8">
            <h1 className="my-4 font-semibold tracking-wide text-sunshine">
              Top Rated Dealer
            </h1>
            <h1 className="text-5xl font-bold tracking-tight text-shadow-sm">
              Explore Our Top Deals
            </h1>
            <div className="flex items-center justify-center gap-1 my-4 text-sunshine">
              <div className="justify-end">
                <div className="w-10 mt-1 border bg-sunshine border-sunshine"></div>
                <div className="flex justify-end w-10">
                  <div className="w-6 mt-1 border bg-sunshine border-sunshine"></div>
                </div>
              </div>
              <GiFlowerEmblem className="text-2xl" />
              <div>
                <div className="w-10 mt-1 border bg-sunshine border-sunshine"></div>
                <div className="w-6 mt-1 border bg-sunshine border-sunshine"></div>
              </div>
            </div>

            <div ref={topDealsRef} className="relative">
              {isLoadingVehicles ? (
                <div className="absolute top-[50%] right-[50%]">
                  <Loader />
                </div>
              ) : (
                <VehicleList vehicles={vehicles} />
              )}
              {!isLoadingVehicles && nPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  nPages={nPages}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-12 text-center md:py-32 py-8 bg-[#f4f8f9]">
          <h2 className="text-3xl font-bold tracking-wide">
            Why People Like Us?
          </h2>
          <p className="text-sm mx-auto my-3 text-[#c8c8c8] max-w-md">
            People choose Imperial Shuttle for an unparalleled transportation
            experience. Our commitment to excellence, safety, and customer
            satisfaction sets us apart. Here's why riders prefer us.
          </p>
          <div className="grid-cols-4 gap-4 px-4 mx-auto space-y-4 bg-transparent max-w-7xl md:grid">
            {peopleLikes?.map((like) => (
              <Offer
                key={like.title}
                title={like.title}
                description={like.description}
              />
            ))}
          </div>
        </div>
        <Clients />
        <NewsLetter />
        {/* Car by types */}
        <div className="px-5 py-32 text-center bg-white">
          <h2 className="text-3xl font-bold capitalize">Vehicle Types</h2>
          {/* container */}
          <div className="grid grid-cols-2 gap-8 py-12 mx-4 max-w-7xl md:grid-cols-6 md:mx-auto">
            {vehicles &&
              getUniqueValues(vehicles, "type")?.map((vehicleBrand: any) => (
                <CarType brand={vehicleBrand} key={vehicleBrand} />
              ))}
          </div>
        </div>
        <div className="mt-12 text-center md:py-32 py-8 bg-[#f4f8f9]">
          <h2 className="text-3xl font-bold tracking-wide">FAQs</h2>
          <div className="px-4 mx-auto bg-transparent max-w-7xl">
            <FaqAccordion faqs={faqs} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
