import React, { useEffect, useMemo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPaymentDetails, verifyPayment } from '../../store/Payment/paymentSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPrice } from '../../utilities/utilities';
import { GenericRespDataC } from '../../services/http/interfaces/genericResponse';

const PaymentConfirm = () => {
    const dispatch = useAppDispatch();
    const { paymentDetails, status } = useAppSelector(state => state.payment);
    const location = useLocation();
    const navigate = useNavigate()

    const transactionId = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('transaction_id');
    }, [location.search]);

    useEffect(() => {
        if (transactionId) {
            dispatch(getPaymentDetails(transactionId));
        }
    }, [dispatch, transactionId]);

    const handleVerify = async () => {
        if (transactionId) {
            const result = await dispatch(verifyPayment(transactionId));
            const storePayload:GenericRespDataC = result?.payload as GenericRespDataC;
            if (storePayload?.rawBody?.success) {
                navigate('/');
            }
        }
    };

    if (status === 'loading') {
        return <div>Loading...</div>;
    }
    return (
        <div>
            <div className='flex flex-col items-center justify-center w-full h-full py-8 space-y-8'>
                {paymentDetails && (
                    <div className='flex flex-col items-center space-y-4 md:flex-row md:space-y-0'>
                        <FaCheckCircle fill='#34A853' className='mr-0 md:mr-5 h-9 w-9' />
                        <span className="text-[#34A853] text-2xl md:text-4xl font-normal leading-[20px] font-['Plus_Jakarta_Sans']">
                            Transaction successfully
                        </span>
                    </div>
                )}
                <div className='flex flex-col items-center space-y-4 md:space-y-10'>
                    <span className="text-[#000] font-['Plus_Jakarta_Sans'] text-xl md:text-4xl font-medium leading-[20px] text-center md:text-left">
                        {`Thank you! Your payment of ${formatPrice(paymentDetails?.transaction?.amount ? +paymentDetails?.transaction?.amount : 0)} has been received.`}
                    </span>
                    <span className="text-[#626263] font-['Plus_Jakarta_Sans'] text-[14px] md:text-[16px] font-normal leading-[20px] text-center md:text-left">
                        {`Invoice Number: ${paymentDetails?.invoice?.invoiceNumber}`} | {`Transaction ID : ${paymentDetails?.transaction?._id}`}
                    </span>
                    <span className="text-[#626263] font-['Plus_Jakarta_Sans'] text-[14px] md:text-[16px] font-medium leading-[20px] text-center md:text-left">
                        Payment Details
                    </span>
                </div>
                <div className="md:px-0 mx-auto md:mx-56 flex flex-col md:flex-row py-2 px-2 space-y-4 md:space-y-0 justify-center text-[#626263] 
                    font-['Plus_Jakarta_Sans'] text-[14px] md:text-[16px] font-medium leading-[20px] border-r border-b shadow-md">
                    <div className="flex flex-col w-full mr-0 space-y-2 md:mr-10 md:w-80">
                        <span className='p-2'>
                            {`Total Amount: ${formatPrice(paymentDetails?.transaction?.amount ? +paymentDetails?.transaction?.amount : 0)}`}
                        </span>
                        <span className='p-2'>
                            {`Custom Number: ${paymentDetails?.invoice?.customer?.phoneNumber} `}
                        </span>
                    </div>
                    <div className='flex flex-col w-full space-y-2 md:w-80'>
                        <span className='p-2'>
                            {`Customer Name: ${paymentDetails?.invoice?.customer?.firstName} ${paymentDetails?.invoice?.customer?.lastName}`}
                        </span>
                        <span className='p-2'>
                            {`Customer Email: ${paymentDetails?.invoice?.customer?.email}`}
                        </span>
                    </div>
                </div>
                <div className="text-[#000] font-['Plus_Jakarta_Sans'] text-[16px] font-normal leading-[20px] ml-2">
                    <span>
                        Please wait for some time for the amount to show up in your account.
                    </span>
                </div>
            </div>
            <div className='flex justify-center'>
                <button
                    className='border w-36 h-10 rounded-md bg-[#034FCC] text-white'
                    onClick={handleVerify}
                >
                    Verify
                </button>
            </div>
        </div>
    );
}

export default PaymentConfirm;
