export const faqs = [
    {
        question: 'How do I make a reservation?',
        answer:
            'Making a reservation is easy! Simply use our online booking system, enter your rental details, choose your vehicle, and follow the steps to confirm your reservation. For assistance, our customer support is just a call away.',
    },
    {
        question: 'Can I choose the assigned driver for my car rental?',
        answer:
            "Assigned drivers are selected based on availability and expertise. While you can't choose a specific driver, rest assured that all our drivers are licensed, experienced, and undergo thorough background checks.",
    },
    {
        question: 'What qualifications do assigned drivers have?',
        answer:
            'All assigned drivers are licensed, experienced, and have undergone comprehensive background checks. We prioritize safety and professionalism in our driver selection process.',
    },
    {
        question: 'Can I modify or cancel my reservation?',
        answer:
            'Yes, you can modify or cancel your reservation through our website or by contacting our customer service. Please check our cancellation policy for any associated fees or restrictions.',
    },
    {
        question: 'What forms of payment are accepted?',
        answer:
            'We accept major credit cards for payment. Debit cards may be accepted with certain conditions, and a pre-authorization amount may be required. Cash payments are generally not accepted.',
    },
    {
        question: 'What responsibilities do I have as the renter during the assigned driver service?',
        answer:
            'As the renter, you are responsible for the behavior of yourself and your guests. Any damage caused by unruly behavior may result in additional charges. Please adhere to our terms and conditions for a smooth rental experience.',
    },
    {
        question: 'Are there specific pickup and drop-off locations for the assigned driver service?',
        answer:
            'The assigned driver will pick up and drop off you and your guests at the locations specified during the reservation process. Any changes to these locations must be communicated in advance.',
    },
];