import React, { FC, } from "react"
import { Field, ErrorMessage } from "formik"

type Options = { key: string; value: string }

type Props = {
  label?: string
  name: string
  options?: Options[]
  width?: number
}

const Checkbox: FC<Props> = ({
  label,
  name,
  options,
  width,
  ...rest
}) => {


  return (
    <div className="">
      <Field
        name={name}
        {...rest}
      >
        {({ field, form }: any) => {          
          return (
            <span className="flex justify-start space-x-2 my-1 items-center align-middle">
              <input
                className="text-[#9B9DFD] border-primary-borderColor focus:ring-0 focus:ring-offset-0 focus:shadow-none h-5 w-5 rounded-md"
                type="checkbox"
                id={name}
                {...field} // this must come before value attribute
                checked={field.value}
                onChange={(e) => {
                  form.handleChange(e)
                }}
              />
              <label className="text-[12px] font-semibold" htmlFor={name}>
                {label}
              </label>
            </span>
          )
        }}
      </Field>
      <ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} />
    </div>
  )
}

export default Checkbox
