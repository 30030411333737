import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import userReducer from './user/userSlice'
import appReducer from './app/appSlice'
import messageReducer from './message/messageSlice'
import vehiclesReducer from './vehicles/vehiclesSlice'
import paymentDetailReducer from './Payment/paymentSlice'
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedAppReducer = persistReducer(persistConfig, appReducer)
const persistedUserReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
  reducer: {
    app: persistedAppReducer,
    user: persistedUserReducer,
    vehicles: vehiclesReducer,
    message: messageReducer,
    payment: paymentDetailReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
