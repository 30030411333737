import { appSingleton } from "../../configuration/appUtils"
import ApiConstants from "../../configuration/constants"
import { ApiUtils } from "../http/api/rest"
import HttpConstants from "../http/constants"

export class OrderService {
    static async getOrders(customerID: string, page?: number) {
        const response = await (new ApiUtils()).callApi(appSingleton.connection, {
            url: `${ApiConstants.apiGetOrdersUrl}${customerID}?page=${page}&limit=${10}`,
            method: HttpConstants.KEY_GET
        })
        return response?.rawBody
    }
}