import React, { FC } from "react";
import Checkbox from "./fields/Checkbox";
import ContactInput from "./fields/ContactInput";
import ContactTextarea from "./fields/ContactTextarea";
import Datepicker from "./fields/Datepicker";
import Input from "./fields/Input";
import RadioButton from "./fields/RadioButton";
import Select from "./fields/Select";
import Textarea from "./fields/Textarea";

type Comparator = { key: string; value: string };

type Props = {
  control: string;
  type: string;
  name: string;
  min?: number;
  max?: number;
  options?: Comparator[];
  label?: string;
  width?: any;
};

const FormikControl: FC<Props> = ({
  control,
  options, name,
  min,
  type,
  width,
  ...rest
}) => {
  switch (control) {
    case "input":
      return (
        <Input
          width={width}
          type={type}
          name={name}
          min={min}
          {...rest}
        />
      );
    case "ContactInput":
      return (
        <ContactInput
          width={width}
          type={type}
          name={name}
          {...rest}
        />
      );
    case "textarea":
      return <Textarea width={width} name={name} {...rest} />;
    case "ContactTextarea":
      return <ContactTextarea width={width} name={name} type={type} {...rest} />;
    case "select":
      return (
        <Select
          options={options}
          width={width}
          name={name}
          {...rest}
        />
      );
    case "radio":
      return (
        <RadioButton
          options={options}
          width={width}
          name={name}
          {...rest}
        />
      );
    case "checkbox":
      return (
        <Checkbox
          options={options}
          width={width}
          name={name}
          {...rest}
        />
      );
    case "date":
      return <Datepicker width={width} name={name} {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
