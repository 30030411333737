import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../store/hooks";


interface ProtectedRouteProps {
  component?: JSX.Element
}
const ProtectedRoute = (props: ProtectedRouteProps) => {
  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);
  if (isAuthenticated){

    return <Outlet/>
  }
  return <Navigate to="/login" />
};

export default ProtectedRoute;