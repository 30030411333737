import { appSingleton } from "../../configuration/appUtils";
import ApiConstants from "../../configuration/constants";
import { ApiUtils } from "../http/api/rest";
import HttpConstants from "../http/constants";

export class PaymentService {
static async getPaymentDetailsById(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetPaymentDetailsUrl + id,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response;
  }

  static async verifyPaymentById(id: string) {
    const response = await new ApiUtils().callApi(appSingleton.connection, {
      url: ApiConstants.apiGetPaymentVerifiedUrl + id,
      method: HttpConstants.KEY_GET,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjoiNjIyNWU4Zjg4MWFhZDI1NjAyZmRlYjY3IiwiYWRtaW5FbWFpbCI6ImlzaWNoZWlwYXNjYWxAZ21haWwuY29tIiwiaXNTdXBlckFkbWluIjpmYWxzZSwicm9sZSI6IkFkbWluIiwiaWF0IjoxNjQ5NjI3MTkwLCJleHAiOjE2NDk3MTM1OTAsImF1ZCI6IkltcGVyaWFsLVNodXR0bGUiLCJpc3MiOiJpbXBlcmlhbC1zaHV0dGxlLWFwcC1hcGkiLCJzdWIiOiJpbXBlcmlhbC1zaHV0dGxlLWFwaSJ9.fnOkgwDy2Lc03P-eYAdyu8Nrldvll3HMJjNsvfkb4fQ`,
      },
    });
    return response;
  }
}