import React, { FC } from "react"
import { Field, ErrorMessage } from "formik"


type Options = { key: string; value: string }

type Props = {
  label?: string
  name: string
  options?: Options[]
  width?: number
}

const RadioButton: FC<Props> = ({ label, name, options, width, ...rest }) => {
  return (
    <div className="">
      
      <Field
        name={name}
        {...rest}
        className={`w-[${width}]border-2 border-secondary-grayLighter text-xs rounded-md`}
      >
        {({ field }: any) => {
          
         return options?.map((option: any) => {
            return (
              <span
                key={option?.key}
                className="flex justify-start space-x-2 items-center align-middle"
              >
                <input
                  type="radio"
                  id={option?.value}
                  {...field} // this must come before value attribute
                  value={option?.value}
                  checked={field.value === option.value}
                />
                <label htmlFor={option?.value}>{option?.key}</label>
              </span>
            )
          })
        }}
      </Field>
      <ErrorMessage name={name}  render={msg => <div className="text-[12] text-[red]">{msg}</div>} />
    </div>
  )
}

export default RadioButton
